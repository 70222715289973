import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ComponentFactoryResolver, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiPathsService } from 'src/app/api-paths.service';
import { LISTComponent } from 'src/app/task/list/list.component';
import { NewsComponent } from 'src/app/task/news/news.component';
import { environment } from 'src/environments/environment';
import { CampoUtility } from "../../models/campo-aggiuntivo";
import { ToastrService } from 'ngx-toastr';
import { values } from 'd3';


@Component({
  selector: 'app-check-per-data',
  templateUrl: './check-per-data.component.html',
  styleUrls: ['./check-per-data.component.scss']

})
export class CheckPerDataComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  storico = false;
  filtro: any = {};
  last: any;
  dati;
  @Input() cliente: number;
  @Input() clienteObject: any;
  environment = environment;
  result: any;
  TasklistLoaded: boolean = false;
  constructor(private ApiPaths: ApiPathsService, private campoUtility: CampoUtility, private datepipe: DatePipe, private modalService: NgbModal, private injector: Injector, private resolver: ComponentFactoryResolver, private toastrService: ToastrService) { }
  ngOnInit(): void {
    var now = new Date();
    this.filtro.inizio = this.datepipe.transform(new Date(1990, 0, 1, 0, 0), 'yyyy-MM-dd');
    this.filtro.fine = this.datepipe.transform(new Date(now.getFullYear() + 1, 0, 0, 23, 59), 'yyyy-MM-dd');
    this.Refresh();


  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("changed", this.container && !this.TasklistLoaded, this.container, this.TasklistLoaded)
    if (changes && changes['storico']?.currentValue != changes['storico']?.previousValue)
      this.loadTaskList();
  }

  loadTaskList() {
    console.log("load", this.container != undefined && !this.TasklistLoaded, this.container, this.TasklistLoaded)
    if (this.container && !this.TasklistLoaded) {
      const factory = this.resolver.resolveComponentFactory(LISTComponent);
      this.container.clear();
      var componentRef = this.container.createComponent(factory);
      componentRef.instance.type = 'V'
      componentRef.instance.cliente = this.cliente;
      componentRef.instance.clienteObject = this.clienteObject;
      componentRef.instance.hideAddButton = true;
      componentRef.instance.filter = '0';
      this.TasklistLoaded = true;
    }
  }

  ngAfterViewInit(): void {

  }

  switchStorico() {
    this.storico = !this.storico;
    this.loadTaskList();
  }

  Refresh() {
    this.filtro.cliente = this.cliente
    this.ApiPaths.ClassicPost('/task/valutazioni/day', this.filtro).subscribe(
      (data) => {
        this.dati = { data: data.data.map(x => {return { label: x.label, values: x.values.reverse(), cat: x.cat, id: x.id}}), valutazioni: data.valutazioni.reverse() };
        console.log("Dati", this.dati)
      }
    )
  }


  add() {
    this.addDynamicButton(this.campoUtility.getDynamicTaskSctructure('V'))
  }

  Scaduto(item) {
    if (!item.data_fine_effettiva) return false;
    var Scadenza = new Date(item.data_fine_effettiva);
    return Scadenza <= new Date();

  }

  inFieri() {
    this.ApiPaths.ClassicPost('/task/valutazioni/last', { cliente: this.cliente }).subscribe(
      data => {
        this.last = data?.data;
        if (this.last.inFieri) {
          this.toastrService.warning("E’ necessario completare il primo PTR per poterne generare un altro.");
          return;
        }
        this.addDynamicButton(this.campoUtility.getDynamicTaskSctructure('V'), true)
      }
    )

  }

  edit(valutazione) {
    const editmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>editmodal.componentInstance).DynamicFields = this.campoUtility.getDynamicTaskSctructure('V');
    (<NewsComponent>editmodal.componentInstance).IsModal = true;

    (<NewsComponent>editmodal.componentInstance).Item = valutazione;

    (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
      this.Refresh();
    });
  }

  addDynamicButton(dynamicField, inFieri = false) {
    const editmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>editmodal.componentInstance).DynamicFields = dynamicField;
    (<NewsComponent>editmodal.componentInstance).IsModal = true;
    const lastObiettivi = inFieri ? (this.last?.figli?.map(x => {
      var obiettivo = Object.assign({}, x);
      obiettivo.id = undefined;
      return obiettivo;
    }) ?? []) : [];

    (<NewsComponent>editmodal.componentInstance).Item = {
      cliente: this.cliente,
      clienteObject: this.clienteObject,
      checks: inFieri ? (this.last?.checks ?? []) : [],
      figli: lastObiettivi ?? [],
      inFieri: inFieri
    };

    (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
      if (data.id)
        lastObiettivi?.forEach(element => {
          element.valutazione = data.id;
          this.ApiPaths.ClassicPost('/task/obiettivi/add', element).subscribe();
        });
      this.Refresh();
    });


  }


}
