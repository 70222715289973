import { Component, Inject, Input } from "@angular/core";
import { CampoAggiuntivo } from "../models/campo-aggiuntivo";

@Component({
    selector: 'app-tooltip-icon',
    template: `
        <a *ngIf="tooltipText()" href="javascript:void(0)" [ngClass]="aClass()" [ngbTooltip]="(!isCustomTooltip() && !isHtmlTooltip()) ? tooltipText() : null">
            &nbsp; <i [ngClass]="iclass" [ngStyle]="{ 'color': color }" ></i>
             <span *ngIf="isCustomTooltip()" class="modal-tooltip-text" > {{tooltipText()}} </span>
             <div *ngIf="isHtmlTooltip()" class="modal-tooltip-text" [innerHTML]="tooltipText()"></div>
        </a>
    `,
})
export class TooltipIcon {

    @Input() campo: CampoAggiuntivo;
    @Input() aclass: string = 'link font-16 text-info ';
    @Input() iclass: string = 'far fa-question-circle';
    @Input() color: string;

    aClass = () => { 
        return !this.isTooltip() ? `status-icon ${this.aclass}` : this.aclass;
    }

    isCustomTooltip() {
        return this.campo.customTooltip != undefined;
    }
    isTooltip() {
        return this.campo.tooltip != undefined;
    }
    isHtmlTooltip() {
        return this.campo.htmlTooltip != undefined;
    }

    tooltipText = () => {
        return this.campo.customTooltip ?? this.campo.htmlTooltip ?? this.campo.tooltip;
    }


}