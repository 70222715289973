<ng-container *ngFor="let sezione of Campi" >
  <div [class.card]="sezione.class == undefined" *ngIf=" permission.isOperativeRoles(sezione.allowedRoles) && permission.isntOperativeRoles(sezione.prohibitedRoles) && permission.HasCapacity(sezione.allowedCapacity) && (campoUtility.Show(sezione, Oggetto,  true) || campoUtility.Show(sezione, Oggetto,  false)) " [ngClass]="sezione?.class" [ngStyle]="sezione?.style">
    <div [class.card-body]="sezione.bodyClass == undefined" [ngClass]="sezione.bodyClass" [ngStyle]="sezione.bodyStyle" >
      <h5 class="card-title" *ngIf="sezione.sezione" [ngStyle]="sezione?.titleStyle" [ngClass]="sezione?.titleClass" >{{sezione.sezione}}</h5>
      <app-field-viewer  *ngIf="!sezione.campo" (CustomButton)="customButton($event)" [(DropdownSources)]="DropdownSources" [(Campi)]="sezione.campi" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled" (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
      <app-field-viewer *ngIf="sezione.campo && Oggetto[sezione.campo]" (CustomButton)="customButton($event)" [(Padre)]="Oggetto" [(DropdownSources)]="DropdownSources" [(Campi)]="sezione.campi" [(Oggetto)]="Oggetto[sezione.campo]" [isModifyDisabled]="isModifyDisabled" (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
    </div>
  </div>
<!--  <div [class.card]="sezione.class != undefined" *ngIf="!sezione.sezione && permission.isOperativeRoles(sezione.allowedRoles) && permission.isntOperativeRoles(sezione.prohibitedRoles) && permission.HasCapacity(sezione.allowedCapacity)" [ngClass]="sezione?.class" [ngStyle]="sezione?.style">-->
<!--    <div [class.card-body]="sezione.bodyClass != undefined" [ngClass]="sezione.bodyClass" [ngStyle]="sezione.bodyStyle" >-->
<!--      <app-field-viewer *ngIf="!sezione.campo" [(DropdownSources)]="DropdownSources" [(Campi)]="sezione.campi" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled" (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>-->
<!--      <app-field-viewer *ngIf="sezione.campo && Oggetto[sezione.campo]" [(DropdownSources)]="DropdownSources" [(Campi)]="sezione.campi" [(Oggetto)]="Oggetto[sezione.campo]" [isModifyDisabled]="isModifyDisabled" (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>-->
<!--    </div>-->
<!--  </div>-->
</ng-container>

