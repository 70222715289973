import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CampoAggiuntivo, CampoUtility } from "../models/campo-aggiuntivo";
import { DynamicCrudService } from "../_services/dynamic-crud.service";
import { environment } from 'src/environments/environment';
import { FileStoreService } from '../_services/file-store.service';
import { ApiPathsService } from 'src/app/api-paths.service';

@Component({
    templateUrl: './dynamic-edit.component.html'
})
export class DynamicEditComponent implements OnInit {
  environment = environment;
  @Input() DropdownSources: any = {};
  @Input() IsModal: boolean = true;
  @Input() ExternalAdd: boolean = false;
  @Input() ExternalSave: boolean = false;
  @Input() FromLista: boolean = false;
  @Input() Item: any ={};
  @Input() CrudSettings: any;
  @Output() updated = new EventEmitter<any>();
  Stampe: any;
  Refreshing: boolean = false;
  ngbActiveModal: NgbActiveModal;
  bup: any = {};
  constructor(
      private injector: Injector,
      public campoUtility: CampoUtility,
      private crudService: DynamicCrudService,
      private api: ApiPathsService,
      private filestore: FileStoreService,
  ){
    if (true) {
      this.ngbActiveModal = <NgbActiveModal>this.injector.get(NgbActiveModal);
    }
  }

  ngOnInit(): void {
      this.Refresh();
  }
    
  Refresh(){ 
    this.Refreshing = true;
    if(this.Item?.id && (this.CrudSettings.HasGet ?? true)) 
      this.crudService.GetItem(this.CrudSettings, {id: this.Item?.id}).subscribe(data=>{
        this.SetData(this.CrudSettings.GetResponseHasData ? data : {data: data})
      })
    else 
      this.SetData({data: this.Item} ?? {data: {}})
  }

  SetData(data) {
    this.bup = Object.assign({}, data.data);
    console.log("Bup", this.bup)
    this.Item = data.data;
    this.Stampe = data?.stampe;
    this.crudService.InitObject(this.Item, this.CrudSettings);
    this.FormBuilder();
    if(this.Item?.id){
      this.filestore.SetListaFile(this.Item.files);
      this.filestore.SelectSection(this.CrudSettings.RestRoute);
    }
    setTimeout(() => {
      this.Refreshing = false;
    }, 300)
  }

  FormBuilder(){

    this.CrudSettings?.sezioni?.forEach(sezione=>{
      sezione.campi?.forEach(campo=>{
        this.SetCampoSource(this.Item, campo);
      })
    });

    this.CrudSettings?.campi?.forEach(campo=>{
        this.SetCampoSource(this.Item, campo);
    })

  }
  

  save(close?: boolean) {
    if(!this.ExternalSave)
    this.crudService.Save(this.Item, this.CrudSettings, close, this.ExternalAdd, (saved)=>{
      console.info("Saved: ", {
        Response: saved,
        Item: this.Item,
        Settings:this.CrudSettings,
        close: close, 
        ExternalAdd: this.ExternalAdd
      })
      this.saved(saved.Item, saved.close)
    }, this.bup)
    else {
      
      this.Item = this.CrudSettings.OnSaveMap ? this.CrudSettings.OnSaveMap(this.Item, this.api, this.bup) : this.Item;
      this.saved(this.Item, close)
    }
     
  }

  saved(data, close?: boolean) {
    if (data.id)
      this.Item.id = data.id ?? this.Item.id;
    this.updated.emit(Object.assign({}, this.Item));
    this.Refresh();
    if (close)
      this.close();
  }

  onFileChanged() {
    this.Refresh();
  }

  ChangeHandler(item: { OnChange: string; Event: any, CampoAggiuntivo?: CampoAggiuntivo }) {
    console.log("Changehandler: ", item);
    switch (item.OnChange ) {
      case 'OnValutazionePersonaChange':
        if (this.Item.persona_id && !this.Item.id)
        this.api.ClassicPost('/anagrafica/clienti/mansioni/list', {
          page: 0, size: 10, stati: ['IC'], persona_id: this.Item.persona_id
        }).subscribe((res: { data: any[]; total: number; }) => {
          const Abilita = res.data.reduce((acc, mansione) => {
            return acc.concat(mansione.mansioneObject?.abilitaObject || []);
          }, []);
          this.Item.valutazioni = Abilita.map((abilita) => { return { abilita: abilita.id, abilitaObject: abilita, voto: null, note: '' }; });

        })
        return;
    }
  }

  getparam(oggetto, param){
    return oggetto ? oggetto[param]: null;
  }

  customButton(selected, button) {
    this.crudService.CustomButtonClick(this.Item, button, false, this.CrudSettings,(x)=>{this.updated.emit(x)}, (x)=>{this.saved(x, button.close);}, (x)=>{this.close();} );
  }

  FilterHandler($event: {OnFilter: string; Event: any}) {

  }

  SetCampoSource(item, campo){
    
  }

  close(){
    this.ngbActiveModal.dismiss();
  }
      
}