import { Check } from "src/app/formazione/_Models/Check";
import {TranslateObj} from "../../shared/models/translatedesc";

export class Categorie {
  id: number;
  categoria: string;
  figli: Categorie[];
  foglie?: Check[];
  cat_id?: number;
  id_immagine?: number;
  immagine?: string;
  file?: string;
  UrlImmagine?:string;
  transaltedobjects?: any;
  traduzioni?: TranslateObj[];
  ordine?: number;
  slug?: string | undefined;
  colore?: string;
  fileIcona?: string;
  icona?:string;
  standby?: boolean;
  Cat?: Categorie;
}
