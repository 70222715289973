import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Location} from "@angular/common";
import {ApiRestService} from "../_services/api-rest.service";
import {ToastrService} from "ngx-toastr";
import {ApiPathsService} from "../../api-paths.service";
import {RmaStoreService} from "../_services/rma-store.service";
import { Subscription} from "rxjs";
import {Rma} from "../_Models/rma";
import {ClassicType} from "../../shared/models/ClassicType";
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";
import {Azienda} from "../../Admin/models/Azienda";
import {AuthenticationService} from "../../Login/_services/authentication.service";
import {Persona} from "../../Admin/models/persona";
import {PersonaleStoreService} from "../../Admin/_service/personale-store.service";
import {environment} from "../../../environments/environment";
import {DownloadService} from "../../shared/_services/download.service";
import {ApiRestService as sharedApiRestService} from "../../shared/_services/api-rest.service"
import {ApiRestService as AdminApiRestService} from "../../Admin/_service/api-rest.service"
import {Scadenza} from "../../Scadenzario/Models/Scadenza";
import {ScadenzeStoreService} from "../../Scadenzario/_services/scadenze-store.service";
import {SerialKey} from "../_Models/serial-key";
import {Permission} from "../../Login/_guards/Permission";
import {FileStoreService} from "../../shared/_services/file-store.service";
import {EditAttivitaRmaComponent} from "../edit-attivita-rma/edit-attivita-rma.component";
import {SelezionaClientiComponent} from "../../mappa/seleziona-clienti/seleziona-clienti.component";
import {ActivatedRoute, Router} from "@angular/router";
import {RicercaPaginazione} from "../../Admin/models/sendpersonale";
import {EditEsitiRmaComponent} from "../edit-esiti-rma/edit-esiti-rma.component";
import {ProvaAcquisto} from "../_Models/prova-acquisto";
import {RmaProdottoAddComponent} from "../rma-add/rma-prodotto-add.component";
import {take, takeWhile, first} from "rxjs/operators";
import {HotKeyService} from "../../shared/_services/hot-key.service";
import {SelectDocModelComponent} from "../select-doc-model/select-doc-model.component";
import {CampoAggiuntivo, CampoUtility} from "../../shared/models/campo-aggiuntivo";

@Component({
  selector: 'app-rma-edit',
  templateUrl: './rma-edit.component.html'
})
export class RmaEditComponent implements OnInit {
  environment = environment
  @Output() updated = new EventEmitter<Rma>();
  @Output() added = new EventEmitter<Rma>();
  disclaimer: string = "Accetta i termini privacy";
  @Output() onClose = new EventEmitter();
  IsModal: boolean = true;
  showAnagrafica: boolean = false;
  authorization: string = 'Bearer '+ this.authSvc.currentUserValue.access_token.toString();
  subscriptions: Subscription = new Subscription();
  shortcutLock: boolean = false;
  selected: Rma;
  types: ClassicType[] = [];
  selectedTypes: any[] = [];
  dropdownSettings:IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'label',
    idField: 'id'
  };
  TecniciDropdownSettings:IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'fullName',
    idField: 'id'
  };
  ClienteDropdownSettings:IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'nominativo',
    idField: 'id',
    searchPlaceholderText: "Cerca Qui...",
    allowSearchFilter: true,
  };
  PAdropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    singleOutput: true,
    idField: 'id',
    textField: 'nome',
    searchPlaceholderText: "Cerca",
    allowSearchFilter: true,
    allowRemoteDataSearch: false,
  };
  selectedPA: ProvaAcquisto;
  SelectedSerialKeys: SerialKey[] = [];
  DescRequired: boolean = false;
  selectedTecnici: Persona[] = [];
  tecnici: Persona[] = [];
  priorita: boolean;
  saveDisabled: boolean = false;
  stampe: any[];
  Difetti: any[];
  difettilist: ClassicType[] = [];
  Brand: ClassicType[] = [];
  Brands: ClassicType[] = [];
  Assistenze: Azienda[] = [];
  Assistenza: Azienda[] = [];
  private Dump: string;
  private assistenza_Backup: Azienda[] = [];
  public pdfSource: string;
  Card: boolean;
  DropdownSources: any = {
    PersoneSource: [],
    difettilist: [],
    ProveAcquisto: [],
    SediSource: [],
    AziendeSource: [],
    PrioritaSource: [],
    PeriodiSource:[]
  };
  backup: Rma;
  campiAggiuntiviService: CampoAggiuntivo[] = [
    {
      nome: 'Il Guasto è',
      source: '/rma/rma/guasti',
      responseHasData: false,
      prevDivClass: "col-md-12",
      oggetti: ['guasto'],
      internalOutput: 'guastoObject',
      OutputField: 'guasto',
      modificabile: true,
      inputType: 'singleDropdown',
      placeholder: 'Guasto',
      settings: {
        singleSelection: true,
        closeDropDownOnSelection: true,
        singleOutput: true,
        idField: 'id',
        textField: 'label',
        searchPlaceholderText: "Cerca",
        allowSearchFilter: true,
        allowRemoteDataSearch: true,
      },
      class: 'col-lg-3',
      visibleifProhibited: true
    },
  ]
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedApiRest: sharedApiRestService,
    private downloadService: DownloadService,
    private location: Location,
    private apiRestService: ApiRestService,
    private RmaStore: RmaStoreService,
    private PersonaleStore: PersonaleStoreService,
    private toastService: ToastrService,
    public authSvc: AuthenticationService,
    public apiPaths: ApiPathsService,
    private scadenzeStore: ScadenzeStoreService,
    public permission: Permission,
    private adminApiRestService:AdminApiRestService,
    private filestore: FileStoreService,
    private hotkeys: HotKeyService,
    public campoUtility: CampoUtility,
  ) { }



  Init(id: number) {
    if(id){
      this.IsModal = false;
      this.RmaStore.updateSelected({id:id});
    }
    //this.apiPaths.ClassicGet("/prodotti/parti/types").subscribe();
    this.subscriptions.add(this.RmaStore.$selectedProdotto.subscribe(data => {
      data.data['persona'] = data.data['persona'] ?? {};
      this.showAnagrafica = data?.data && !isNaN(data?.data?.id);
      this.selected = data?.data ?? new Rma();
      this.selected['currentUserId'] = this.authSvc.currentUserValue?.id;
      this.backup = Object.assign({}, data?.data ?? new Rma());
      this.stampe = data?.stampe ?? [];
      this.selected.clienteObject = this.selected?.clienteObject ?? this.selected?.sedeObject?.clienteObject
      if(environment.RmaAddOn['ProveAcquistoMultipleRma'] && data?.data?.id)
        this.apiRestService.getProveAcquistoSede({id: data.data.sedeId}).subscribe(data=>{
          this.DropdownSources.ProveAcquisto = data?.data ?? [];
        })
      this.selected.chiusoObject = {id: this.selected.chiuso, label: this.selected.chiuso? 'Chiuso': this.selected.chiuso == null ? 'NA' : 'Non Chiuso' }

      console.log("Stampe", this.stampe)
      console.log("RMA: ", this.selected)
      this.FormBuilder(this.selected);
      this.filestore.SetListaFile(data?.data?.files);
      this.filestore.SelectSection('rma');
      this.pdfSource = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
      if( environment.RmaAddOn['SezioniRmaEdit']?.some(x=> x['campi']?.some(y=>y['source'] == 'PersoneSource'))) {
        var filter = new RicercaPaginazione('', 1, 20);
        filter['checks'] = this.selected['attivita']?.filter(x=> x.risposta)?.map(x=> x.id);
        this.adminApiRestService.getListaPersonale(filter).subscribe(data => this.DropdownSources.PersoneSource = data?.data ?? []);
      }
    }));
    if (this.permission.isntOperativeRoles(["CA", "C", "AC"]) && this.environment.RmaAddOn['Assistenza']?.Enabled && this.permission.HasCapacity(['menu-clienti']) )
      this.subscriptions.add(this.adminApiRestService.getListaAziende({
        page: 0,
        size: 10,
        ruolo: 'CA'
      }).subscribe(data => this.Assistenze = data?.data ?? this.Assistenze))
  }

  AddShortcut(){
    this.hotkeys.addShortcut({ keys: 'alt.a' }).pipe(first()).subscribe(x=>{
      if (this.shortcutLock == true || !this.selected) return;
      this.shortcutLock = true;
      this.selected['dispPersonale'] = true;
      this.selected['tempi' ] = true;
      this.selected['criticita'] = true;
      this.selected['dispAttrezzature'] = true;
      if(!this.selected.id){ // Add
        this.apiRestService.AddRma(this.FormGetter()).subscribe(data=>{
          this.approvaPianificazione(data?.id)
        },error => this.shortcutErrorHandler());
      }else {
        this.apiRestService.UpdateRma(this.FormGetter()).subscribe(data=>{
          this.approvaPianificazione(this.selected.id)
        },error => this.shortcutErrorHandler());
      }
    });
    this.hotkeys.addShortcut({ keys: 'alt.c' }).pipe(first()).subscribe(x=>{
      if (this.shortcutLock == true) return;
      this.shortcutLock = true;
      this.selected['chkDoc'] = true;
      this.selected['chkMisure' ] = true;
      this.selected['chkProcedura'] = true;
      this.selected['chkCoerenza'] = true;
      if(this.selected && !this.selected.id){ // Add
        this.apiRestService.AddRma(this.FormGetter()).subscribe(data=>{
          this.cancelClick();
        },error => this.shortcutErrorHandler());
      }else if(this.selected){
        this.apiRestService.UpdateRma(this.FormGetter()).subscribe(data=>{
          this.updated.emit(data);
          this.cancelClick();
        },error => this.shortcutErrorHandler());
      }
      //this.save();
    });
  }
  shortcutErrorHandler(){
    setTimeout(()=>{
      this.shortcutLock = false;
      this.AddShortcut();
    }, 20000)
  }
  approvaPianificazione(id:number){
    this.apiPaths.ClassicPost('/rma/rma/approvazione',{id: id}).subscribe(()=>{
      this.updated.emit({id: id});
      this.cancelClick();
      this.toastService.success('Commessa approvata');
    },error => this.shortcutErrorHandler())
  }
  ngOnInit(): void {
    this.route.params.subscribe(data => {
      let id = parseInt(data['rma_id']);
      this.Init(id);
    })
    console.log("NgOnInit")
    this.AddShortcut();
  }

  FilterHandler(event: {OnFilter: string, Event: string}){
    switch (event.OnFilter){
      case "filtroManager":
        this.filtroManager(event.Event);
        break;
    }
  }

  ApprestamentiSelezionabili(){
    return this.DropdownSources.ProveAcquisto.filter( x=> this.selected?.proveObject?.find(y=> y.id == x.id) == undefined )
  }

  addPA(selectedPA: ProvaAcquisto){
    if (this.selected?.proveObject == undefined)
      this.selected.proveObject = [];
    if (this.selected?.prove == undefined)
      this.selected.prove = [];
    if (selectedPA?.id) {
      selectedPA.stampa = false;
      this.selected?.proveObject?.push(selectedPA);
      this.selected?.prove?.push(selectedPA?.id);
      this.selectedPA = undefined;
    } else
      this.toastService.warning("Attenzione selezionare un apprestamento");
  }

  filtroManager(item: string) {
    if (item.length >= 3) {
      var filter = new RicercaPaginazione(item, 0, 1);
      filter['checks'] = this.selected['attivita']?.filter(x=> x.risposta)?.map(x=> x.id);
      this.adminApiRestService.getListaPersonale(filter).subscribe(data=> this.DropdownSources.PersoneSource = data?.data ?? []);
    }
  }
  getTextPec(){
    return "Con la presente Vi informiamo che la Vs. proposta contrattuale, inerente la verifica degli impianti elettrici di cui al DPR 462/01, è stata ACCETTATA e che è stata aperta la relativa pratica con il n. a cui si farà riferimento per ogni successiva comunicazione.\n" +
      "Vi informiamo che al più presto sarete contattati dal nostro Ispettore incaricato di eseguire le verifiche da Voi richieste. In questa fase sarà indispensabile la Vostra preziosa collaborazione per garantire tempestività alla procedura.\n" +
      "Al fine di garantire l’accesso agli impianti nel pieno rispetto dell’art. 26 del D. Lgs. 81/2008 chiediamo di voler fornire tutte le indicazioni necessarie dal punto di vista della sicurezza per lo svolgimento dei lavori presso il vostro impianto (eventuali DPI necessari, eventuali regole di accesso e comportamento, ecc.). A tal proposito siamo a disposizione per fornire tutte le informazioni relative alla nostra attività, che dovessero necessitare, oltre a quelle già fornite.\n" +
      "Nel caso la presente comunicazione NON sia stata inviata via pec, Vi preghiamo di volerci trasmettere conferma di ricezione, a mezzo fax, inviando la presente firmata al numero 080/4315946, oppure a mezzo e-mail, all’indirizzo amministrazione@verificaspa.it, indicando all’oggetto “Accettazione proposta contrattuale n. e nel testo “OK” oppure “RICEVUTO”.\n" +
      "Nel ringraziarVi per la fiducia accordataci, inviamo distinti saluti."
  }
  getparam(oggetto, param){
    return oggetto ? oggetto[param]: null;
  }
  customButton(button: { type?: 'GET'| 'POST', restRoute?: string, preMex?: string, successMex?: string, parameter?: any[], preAlert: string, noClose?: boolean}) {
    if(this.selected && (!button.preAlert || confirm(button.preAlert))){
      this.apiRestService.UpdateRma(this.FormGetter()).subscribe(data=>{

        var filter ={};
        button.parameter?.forEach(param=>{
          var p = param?.parameter?.split('.');
          var parameter = Object.assign({}, this.selected)
          p?.forEach(el=>{
            parameter = this.getparam(parameter,el);
          })
          filter[param.nome] = param.value ?? parameter
        })
        if(button.preMex)
          this.toastService.warning(button.preMex);
        if(button.type == 'GET' && button.restRoute)
          this.apiPaths.ClassicGet(button.restRoute).subscribe(data=>{
            if(button.successMex)
              this.toastService.success(button.successMex);
            this.updated.emit(data);
            this.Refresh();
            if(!button.noClose)
            this.cancelClick();
          })
        if(button.type == 'POST' && button.restRoute)
          this.apiPaths.ClassicPost(button.restRoute,filter).subscribe(data=>{
            if(button.successMex)
              this.toastService.success(button.successMex);
            this.updated.emit(data);
            this.Refresh();
            if(!button.noClose)
            this.cancelClick();
          })
      });
    }

  }

  Refresh(){
    if(this.permission.isntOperativeRoles(["CA", "C", "AC"]) && this.environment.RmaAddOn['Assistenza']?.Enabled)
      this.subscriptions.add(this.adminApiRestService.getListaAziende({page:0, size:10 , ruolo: 'CA'}).subscribe(data=> this.Assistenze = data?.data ?? this.Assistenze))
    this.RmaStore.updateSelected(this.selected);
  }

  FormBuilder(item: Rma){
    this.priorita = item?.priorita != 0;
    environment.RmaAddOn['SezioniRmaEdit']?.forEach(sezione=>{
      sezione.campi?.forEach(campo=>{
        if((campo['type'] == 'checkboxArray' || campo['type'] == 'checkboxArrayMEXC') && (!campo['filter'] || this.selected?.sedeObject?.clienteObject?.id)) {
          console.log(item[campo['oggetti'][0]]);
          this.apiRestService.GetCampiAggiuntiviRmas(item[campo['oggetti'][0]]?.filter(x => typeof x == 'number' ? true : x.risposta) ?? [], campo['oggetti'][0], campo['filter'] ? {id: this.selected?.sedeObject?.clienteObject?.id} : null).subscribe(data => {
            this.selected[campo['oggetti'][0]] = data;
          });
        }
        if(campo['source'] == 'PrioritaSource')
          this.apiPaths.ClassicGet('/rma/rma/priorita').subscribe(data => this.DropdownSources.PrioritaSource = data ?? []);
        if(campo['source'] != 'PrioritaSource') {
          // this.apiPaths.ClassicGet('/rma/rma/priorita').subscribe(data => this.DropdownSources.PrioritaSource = data ?? []);
          this.priorita = item?.priorita != 0;
        }
        if(campo['source'] == 'PeriodiSource'){
          this.apiPaths.ClassicGet('/rma/rma/periodi').subscribe(data=>{
            this.DropdownSources.PeriodiSource = data;
          })
        }
      })
    })


    this.Dump = this.fulladdr(this.selected.ritiro)
    this.selected.ritiro = !this.selected.ritiro ? new Azienda() : this.selected.ritiro;
    this.selected.assistenza = !this.selected.assistenza ? new Azienda() : this.selected.assistenza;
    // this.selected.managerObject = this.selected.manager ?? null;
    this.selected.acquirente = !this.selected.acquirente ? new Azienda() : this.selected.acquirente;
    this.selectedTypes = [];
    this.onDifettoChange();
    this.tecnici = item?.referenti ? item?.referenti : [];
    this.Difetti = item?.difettoObject ? [item?.difettoObject] : [];
    this.assistenza_Backup = item?.assistenzaObject ? [item?.assistenzaObject] : [];
    this.Assistenza = item?.assistenzaObject ? [item?.assistenzaObject] : [];
    this.SelectedSerialKeys = item?.prodotto ? [item.prodotto] :[];

    if(environment.RmaAddOn['Assistenza']?.Tecnico)
      this.selectedTecnici = item?.managerObject ? [item?.managerObject]: [];
    this.Brand = item?.brandObject ? [item?.brandObject]: [];
    if( this.selected?.id && environment.RmaAddOn['Assistenza']?.Tecnico)
      this.PersonaleStore.getListaPersonale({ruolo: environment.IDTecnico, page: 0, size: 15})
  }
  hasPecButton (){
    return this.selected?.id && !(this.selected?.attivita?.some(x => x.risposta)) && environment.RmaAddOn['invioPec'];
  }
  FormGetter(): Rma{
    let rma = Object.assign({}, this.selected);
    rma.progressivo = this.selected?.progressivo?.toString();
    var haspriorita = false;
    environment.RmaAddOn['SezioniRmaEdit']?.forEach(sezione => {
      sezione?.campi?.forEach(campo => {
        if(campo['source'] == 'PrioritaSource')
          haspriorita = true;
        // if(campo['type'] == 'checkboxArray' && campo["oggetti"]?.find(() => true))
        //   rma[campo.oggetti.find(() => true)] = this.selected[campo.oggetti.find(() => true)].map(x => x.id);

      })
    })
    rma.priorita = haspriorita ? this.selected.prioritaObject?.id : (this.priorita ? 1:0);
    rma['includereKm'] = rma['km'] != null && rma['km'] != undefined;
    if(environment.RmaAddOn['SezioniRmaEdit'] && this.selected?.id) {
      let _send = [];
      this.selected?.attivita?.filter(x => x.risposta).forEach(send => {
          const checkbox = Object.assign({},send)
          // checkbox.fields = undefined;
          // checkbox.risposta = undefined;
          // checkbox.check = undefined;
          _send.push(checkbox);
      })
      rma.attivita = _send;
    }
    rma.difetto = this.GetClassicTypeIntFirstID(this.Difetti);
    rma.brand = this.FirstID(this.Brand);
    rma.acquisto = this.selected?.acquistoObject?.id;
    if(environment.RmaAddOn['Assistenza']?.Tecnico)
      rma.manager = this.FirstID(this.selectedTecnici);
    rma['condizioni'] = this.selected['condizioni']?.filter(x=> x.risposta)?.map(x=> x.id);
    rma['partecipanti'] = this.selected['partecipantiObject']?.filter(x=> !x.InModifica)?.map(x=> x.id);
    rma.assistenzaId = this.GetClassicTypeIntFirstID(this.Assistenza);
    return rma;
  }
  GetClassicTypeIntFirstID(value: any[]): number{
    const ID: string = this.FirstID(value);
    return ID? parseInt(ID) : undefined;
  }
  FirstID(value: any[]){
    return value?.map(x=>x.id)?.find(()=>true);
  }
  cancelClick() {
    this.onClose.emit();
    if(this.IsModal)
      // this.ActivemodalService.dismiss();
      this.modalService.dismissAll();
    else
      this.location.back();
  }
  save(){
    if(this.environment.ClientiAddOn.geolocalizzazione && (this.Dump != this.fulladdr(this.selected.ritiro) || !(this.selected?.ritiro?.lat && this.selected?.ritiro?.lon && this.fulladdr(this.selected.ritiro) != "")))
      this.adminApiRestService.getCoordinate(this.fulladdr(this.selected?.ritiro)).subscribe(response => {
        if ( response?.results?.length > 0  &&  response?.results[0]?.geometry?.location) {
          this.selected.ritiro.lat = response.results[0].geometry.location.lat;
          this.selected.ritiro.lon = response.results[0].geometry.location.lng;
          this.Send();
        }
        else
          this.toastService.warning("Le informazioni del luogo di interveno non sono stati trovate")
      })
    else
      this.Send();
  }
  Send(){
    this.saveDisabled = true;
    if(this.selected && !this.selected.id){ // Add
      this.apiRestService.AddRma(this.FormGetter()).subscribe(data=>{
        this.cancelClick();
      },error => this.saveDisabled = false);
    }else if(this.selected){
      this.apiRestService.UpdateRma(this.FormGetter()).subscribe(data=>{
        this.updated.emit(data);
        this.cancelClick();
      },error => this.saveDisabled = false);
    }
  }
  avanti(){
    if(
      (this.selected.telefono || !environment.RmaAddOn.Telefono) &&
      (this.selected.note || !environment.RmaAddOn.DescrizioneDifetto) &&
      (!isNaN(this.GetClassicTypeIntFirstID(this.Difetti)) || !environment.RmaAddOn.CircostanzaDifetto)
      && !isNaN(this.FirstID(this.SelectedSerialKeys))) {
      if (confirm(this.disclaimer)) {
        this.showAnagrafica = true;
        this.save();
      } else
        this.cancelClick();
    }
    else
      this.toastService.error("Inserire tutti i campi", "Attenzione!")
  }

  delete() {
    if (!confirm('Desideri ELIMINARE questo elemento?')) return;
    this.apiPaths.ClassicPost('/rma/rma/delete', { id: this.selected.id }).subscribe(data => { this.cancelClick(); });
  }

  onDifettoChange() {
    this.DescRequired = this.Difetti && this.Difetti.find(x=>x.id == 70 || x.id == 71) != undefined && this.Difetti.find(x=>x.id == 70 || x.id == 71) != null;
  }
  onTecnicoChanged() {}
  CentroAssistenzaCambiato() {
    if(this.Assistenza?.length > 0 && this.FirstID(this.Assistenza) != null && !this.permission.isOperativeRoles(["CA", "C", "AC"]) )
      this.adminApiRestService.getInfoAziende({id:this.FirstID(this.Assistenza)}).subscribe(item =>{
        if(environment.RmaAddOn['Assistenza']?.Tecnico) {
          this.tecnici = item?.referenti ? item?.referenti : [];
          this.selectedTecnici = item.referenti.find(x => x.id == this.FirstID(this.selectedTecnici)) ? this.selectedTecnici : [];
        }
        this.selected.assistenza =
          !(this.assistenza_Backup?.length > 0) ||
          this.FirstID(this.assistenza_Backup) == null ||
          this.FirstID(this.Assistenza) != this.FirstID(this.assistenza_Backup) ?
            item :
            this.selected?.assistenza;
        this.assistenza_Backup = [this.selected.assistenza];
      })
  }
  download(id: number) {
    this.sharedApiRest.downloadFle(id).subscribe(data => {
      this.downloadService.downloadFile(data)
    });
  }
  view(id: number) {
     var  url = `${this.apiPaths?.getBaseAPI()}/file/download/${id?.toString()}?token=${this.authSvc?.currentUserValue?.access_token}`
     console.log(url)
     window.open(url, "_blank");
  }
  ScadAddButton() {
    const nuova = new Scadenza();
    nuova.padre = this.selected.id;
    this.scadenzeStore.updateScadenzaSelezionata(nuova);
    const editmodal = this.modalService.open(EditAttivitaRmaComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<EditAttivitaRmaComponent>editmodal.componentInstance).IsModal = true;
    (<EditAttivitaRmaComponent>editmodal.componentInstance).Item = nuova;
    (<EditAttivitaRmaComponent>editmodal.componentInstance).added.subscribe(data=>{this.Refresh();});
    (<EditAttivitaRmaComponent>editmodal.componentInstance).updated.subscribe(data=> {});
  }
  EsitoAddButton() {
    const nuova = new Scadenza();
    nuova.padre = this.selected.id;
    this.scadenzeStore.updateScadenzaSelezionata(nuova);
    const editmodal = this.modalService.open(EditEsitiRmaComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<EditEsitiRmaComponent>editmodal.componentInstance).IsModal = true;
    (<EditEsitiRmaComponent>editmodal.componentInstance).checkSelezionate = this.selected?.attivita?.filter(x=> x.risposta);
    (<EditEsitiRmaComponent>editmodal.componentInstance).Item = nuova;
    (<EditEsitiRmaComponent>editmodal.componentInstance).added.subscribe(data=>{this.Refresh();});
    (<EditEsitiRmaComponent>editmodal.componentInstance).updated.subscribe(data=> {});
  }
  OpenMap() {
    this.apiRestService.GetCA({lat: !isNaN(this.selected?.ritiro?.lat)? this.selected?.ritiro?.lat: 40.7876777, lon: !isNaN(this.selected?.ritiro?.lon)? this.selected?.ritiro?.lon: 17.1468458, raggio: 15}).subscribe(data=>{
      this.Assistenze = data?.data;
    },error => {}, ()=>{
      const editmodal = this.modalService.open(SelezionaClientiComponent, {
        centered: true,
        backdrop: 'static',
        size: 'xl',
      });
      (<SelezionaClientiComponent>editmodal.componentInstance).centerMaplat = !isNaN(this.selected?.ritiro?.lat)? this.selected?.ritiro?.lat: 40.7876777;
      (<SelezionaClientiComponent>editmodal.componentInstance).centerMaplon = !isNaN(this.selected?.ritiro?.lon)? this.selected?.ritiro?.lon: 17.1468458;
      (<SelezionaClientiComponent>editmodal.componentInstance).circleMarker = { latitude: !isNaN(this.selected?.ritiro?.lat)? this.selected?.ritiro?.lat: 40.7876777, longitude: !isNaN(this.selected?.ritiro?.lon)? this.selected?.ritiro?.lon: 17.1468458, radius: 15000, editable: true, circleDraggable: false, fillColor: "blue", fillOpacity:0.5};
      (<SelezionaClientiComponent>editmodal.componentInstance).IsModal = true;
      (<SelezionaClientiComponent>editmodal.componentInstance).ListaClienti = this.Assistenze;
      (<SelezionaClientiComponent>editmodal.componentInstance).refresh();
      (<SelezionaClientiComponent>editmodal.componentInstance).Selected.subscribe(data=> {
        this.Assistenza = data;
        this.CentroAssistenzaCambiato();
      });
      (<SelezionaClientiComponent>editmodal.componentInstance).onCircleMarkerChange.subscribe(data=> {
        console.log('circle marker log',data);
        this.apiRestService.GetCA({lat: data.latitude, lon: data.longitude, raggio: data.radius/1000}).subscribe(data=>{
          this.Assistenze = data?.data;
          (<SelezionaClientiComponent>editmodal.componentInstance).ListaClienti = data?.data;
          (<SelezionaClientiComponent>editmodal.componentInstance).refresh();
        })
      });
    })
  }

  onFileChanged() {
    this.RmaStore.updateSelected(this.selected);
  }
  fulladdr(cliente: Azienda) : string{
    return (cliente?.indirizzo ?? "") + (cliente?.citta? ("," + cliente?.citta) : "") + (cliente?.provincia? ("," + cliente?.provincia) : "");
  }

  AddRma() {
    const newRma =  new Rma(undefined);
    //(come ad esempio Priorità inserita, Tipo, Codice, penso anche Descrizione del difetto).
    newRma.acquirente = Object.assign({}, this.selected.acquirente);
    newRma.ritiro = Object.assign({}, this.selected.ritiro);
    newRma.proveObject = [];
    newRma.padre =  this.selected?.id;
    newRma.sedeId = this.selected?.sedeId;
    if(this.selected) {
      newRma['codice'] = this.selected['codice'];
      newRma['note'] = this.selected['note'];
      newRma['priorita'] = this.selected['priorita'];
      newRma['partenza'] = this.selected['partenza'];
      newRma['trasferta'] = this.selected['trasferta'];
      newRma['data_scadenza_richiesta'] = this.selected['data_scadenza_richiesta'];
      newRma['km'] = this.selected['km'];
      newRma['persona'] = this.selected['persona'];
      newRma['proveObject'] = this.selected['proveObject'];
      newRma['prioritaObject'] = this.selected['prioritaObject'];
    }
    newRma.tipoObject = Object.assign({}, this.selected?.tipoObject);
    newRma.tipo = this.selected?.tipo;
    newRma.sedeObject = Object.assign({}, this.selected?.sedeObject);
    newRma.clienteObject = Object.assign({}, this.selected?.sedeObject?.clienteObject);
    newRma.clienteId = this.selected?.sedeObject?.clienteObject?.id;
    this.RmaStore.updateSelected(newRma);
    const editmodal = this.modalService.open(RmaProdottoAddComponent, { centered: true, backdrop: 'static', size: 'xl',});
    (<RmaProdottoAddComponent>editmodal.componentInstance).onClose.subscribe (data => this.Refresh() )
  }


  ChangeHandler(item: { OnChange: string; Event: any }) {
    switch (item.OnChange ){
      case 'OnFilesChange' :
        this.Refresh();
        break;
      case "getPrice":
        this.selected['inail'] = parseFloat(this.backup['inail']?.toString() ?? '-1') > -1 ? this.backup['inail'] : this.campoUtility.getChecklistPrice(this.selected['attivita']);
        break;
    }


  }

  CaricaModello() {
    const editmodal = this.modalService.open(SelectDocModelComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<SelectDocModelComponent>editmodal.componentInstance).Segnalazione = this.selected;
    (<SelectDocModelComponent>editmodal.componentInstance).Attivita = this.selected.attivita.filter(x=> x.risposta);
    (<SelectDocModelComponent>editmodal.componentInstance).Update.subscribe(data => {
      this.Refresh();
    });
  }

  CanAddFigli() {
    var hasAssegnabile = false;
    var sezioni: {

      sezione?: string,
      campo?: string,
      campi?: CampoAggiuntivo[]
    }[]= environment.RmaAddOn['SezioniRmaEdit'];
    if(sezioni)
      sezioni.forEach(sezione => {
        if(sezione['campi']?.find(x => x.oggetti.includes('assegnabile') ))
          hasAssegnabile = true;
      })
    return !hasAssegnabile || (this.selected['assegnabile'] && this.selected['dataappuntamento'] && this.selected['documenti'] && this.selected['disponibile']);
  }
}


