import { Component, ComponentFactoryResolver, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef, ApplicationRef, Injector, ComponentRef, ReflectiveInjector } from '@angular/core';
import { CampoAggiuntivo, CampoUtility } from "../models/campo-aggiuntivo";
import { Permission } from "../../Login/_guards/Permission";
import { ToastrService } from "ngx-toastr";
import { ApiPathsService } from "../../api-paths.service";
import { AngularEditorConfig } from "../../../LibrerieCustom/angular-editor/src/public-api";
import { Prodotto } from "../../Progetti/Models/Prodotto";
import { SignaturePad } from 'angular2-signaturepad';


@Component({
  selector: 'app-field-viewer',
  templateUrl: './field-viewer.component.html',
  styleUrls: ['./field-viewer.component.css']
})
export class FieldViewerComponent implements OnInit, OnChanges {
  @Input() Campi: CampoAggiuntivo[];
  @Output() CampiChange = new EventEmitter();
  @Input() DropdownSources: any = {};
  @Output() DropdownSourcesChange = new EventEmitter();
  @Input() Oggetto: any;
  @Output() OggettoChange = new EventEmitter();
  @Input() Padre: any;
  @Output() PadreChange = new EventEmitter();
  @Input() isModifyDisabled;
  @Input() hideLabel;
  @Input() CampiNascosti;
  @Output() OnFilter = new EventEmitter<{ OnFilter: string, Event: any }>();
  @Output() OnChange = new EventEmitter<{ OnChange: string, Event: any, CampoAggiuntivo?: CampoAggiuntivo }>();
  @Input() Type: string;
  @Output() TypeChange = new EventEmitter();
  @Output() CustomButton = new EventEmitter();
  readonlyEditorConfig: AngularEditorConfig = {
    editable: false,
    enableToolbar: false,
    showToolbar: false,

  };
  signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 700,
    'canvasHeight': 200
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    BgColor: '#FFFFFFF',
    toolbarHiddenButtons: [
    ]
  };
  blackEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    BgColor: 'black',
    toolbarHiddenButtons: [
    ]
  };
 

  constructor(
    public permission: Permission,
    public campoUtility: CampoUtility,
    private toastService: ToastrService,
    private apiPaths: ApiPathsService,

    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    this.Campi?.forEach(campo => {
      this.getSource(campo);
    })

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.DropdownSources && this.DropdownSources) {
      this.Campi?.forEach(campo => {
        this.getSource(campo);
      })
    }
    if (changes.Oggetto) {
      this.Campi?.forEach(campo => {
        this.getSource(campo);
      })
    }
  }
  @ViewChild('dynamicContainer', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  createComponent(component): ComponentRef<any> {
    if (!this.container) {
      console.error('Container non inizializzato.');
      return;
    }
    return this.container.createComponent(component);
  }
  outletConfig(item: { component: any, params: any }) {
    console.log('Input', item)
    const inputInjector = ReflectiveInjector.resolveAndCreate(
      [
        {
          provide: 'tooltip',
          useValue: 'tesjhdlakjhflkdjhaskf',
        },
      ],
      this.injector
    );

    return {
      component: item.component,
      injector: inputInjector, // Passiamo l'injector al componente
    };
    // const providers: { provide, useValue }[] = !item?.params ? [] : Object.getOwnPropertyNames(item?.params)?.map(prop => {
    //   return {
    //     provide: prop,
    //     useValue: item.params[prop]
    //   }
    // });
    // const inputInjector = ReflectiveInjector.resolveAndCreate(
    //   providers,
    //   this.injector,
    // );

    // return {
    //   component: item.component,
    //   injector: inputInjector,
    // };
    
  }
  RestCall(campo: CampoAggiuntivo, searchString?: string, urlField?: string) {
    campo.searched = true;
    var filter = {};
    campo.parameter?.forEach(param => {
      var p = param?.parameter?.includes('.') ? param?.parameter?.split('.') : [param?.parameter];
      var parameter = Object.assign({}, this.Oggetto)
      p?.forEach(el => {
        parameter = this.getparam(parameter, el);
      })
      if (param.search)
        filter[param.nome] = searchString
      else
        filter[param.nome] = param.value ?? parameter
    })
    if (campo?.parameter && urlField) {
      this.apiPaths.ClassicPost(campo[urlField], filter).subscribe(data => {
        if (campo.type == 'checkboxArray' || campo.type == 'checkboxArrayMEXC') {
          this.getCheckBox(campo, data)
        } else if (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown' || campo.type == 'CategoriaNav') {
          campo.source = (campo.responseHasData ? data.data : data) ?? [];
        } else {
          var arry = campo.responseHasData ? data.data : data;
          if (arry.length > 1)
            arry[0].first = true;
          this.Oggetto[campo['oggetti'][0]] = arry;
        }
        if (campo.inputType == 'singleDropdown' && campo.source?.length == 1 && typeof campo.internalOutput == 'string' && campo.settings?.selectIfSingle)
          this.Oggetto[campo.internalOutput] = campo.source[0]
        if (campo.inputType == 'singleDropdown' && typeof campo.internalOutput == 'string' && campo.settings?.selectFirst)
          this.Oggetto[campo.internalOutput] = campo.source[0]
      })
    }
    else if (urlField && campo[urlField]) {
      this.apiPaths.ClassicGet(campo[urlField]).subscribe(data => {
        if (campo.type == 'checkboxArray' || campo.type == 'checkboxArrayMEXC') {
          this.getCheckBox(campo, data)
        } else if (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown' || campo.type == 'CategoriaNav') {
          campo.source = (campo.responseHasData ? data.data : data) ?? [];
        }
        else {
          this.Oggetto[campo['oggetti'][0]] = campo.responseHasData ? data.data : data;
        }
        if (campo.inputType == 'singleDropdown' && campo.source?.length == 1 && typeof campo.internalOutput == 'string' && (campo.settings?.selectIfSingle))
          this.Oggetto[campo.internalOutput] = campo.source[0]
        if (campo.inputType == 'singleDropdown' && typeof campo.internalOutput == 'string' && (campo.settings?.selectFirst))
          this.Oggetto[campo.internalOutput] = campo.source[0]
      })
    }
  }


  getCheckBox(campo: CampoAggiuntivo, source: any[]) {
    if (campo.type == 'checkboxArray' || campo.type == 'checkboxArrayMEXC') {

      var selected = Array.isArray(this.Oggetto[campo['oggetti'][0]]) ? [...this.Oggetto[campo['oggetti'][0]]] : [];
      console.log("selected:", selected)
      var selIds = selected.every(item => typeof item === "number") ? selected : selected?.filter(x => x.risposta)?.map(item => item.id);
      var data: { check?: string, id?: number, risposta?: boolean, fields?: CampoAggiuntivo[], codice: string }[] = source.map(check => {
        return {
          check: check.check ?? check.label,
          id: check.id,
          risposta: selIds?.find(x => x == check.id) != undefined,
          fields: this.mapCustomField(check),
          codice: check.codice
        }
      }) ?? [];

      this.Oggetto[campo['oggetti'][0]] = data
    }
  }


  getSource(campo: CampoAggiuntivo, searchString?: string): any[] {

    if ((!campo.hideif?.fieldexist || !campo.hideif?.fieldnotexist || !campo.hideif?.fieldEqual || !campo.hideif?.fieldNotEqual || campo.modificabile == false) && (!this.campoUtility.Show(campo, this.Oggetto, true, this.Type) && !this.campoUtility.Show(campo, this.Oggetto, false, this.Type))) return [];
    if (typeof campo.source == 'string' && campo.source.includes('/'))
      this.RestCall(campo, searchString, 'source');
    else if (campo.type != "Files" && typeof campo.url == 'string' && campo.url?.includes('/'))
      this.RestCall(campo, searchString, 'url');
    else if (typeof campo.source == 'string' && campo?.sourceFilter) {
      campo.source = this.DropdownSources[campo.source]?.filter(x => !campo.sourceFilter || (
        (!campo.sourceFilter?.fieldexist || x[campo.sourceFilter?.fieldexist]) &&
        (!campo.sourceFilter?.fieldnotexist || !x[campo?.sourceFilter?.fieldnotexist]) &&
        (!campo?.sourceFilter?.arrayEmpty || (!x[campo?.sourceFilter?.arrayEmpty] || x[campo?.sourceFilter?.arrayEmpty]?.length >= 0)) &&
        (!campo?.sourceFilter?.arrayFull || x[campo?.sourceFilter?.arrayFull]?.length > 0)))

    }
    else if (campo.source && typeof campo.source != 'string') {
      campo.source = campo.source?.filter(x => !campo.sourceFilter ||
        ((!campo.sourceFilter?.fieldexist || x[campo.sourceFilter?.fieldexist]) &&
          (!campo.sourceFilter?.fieldnotexist || !x[campo?.sourceFilter?.fieldnotexist]) &&
          (!campo?.sourceFilter?.arrayEmpty || (!x[campo?.sourceFilter?.arrayEmpty] || x[campo?.sourceFilter?.arrayEmpty]?.length >= 0)) &&
          (!campo?.sourceFilter?.arrayFull || x[campo?.sourceFilter?.arrayFull]?.length > 0)))
    }

    var elements = !Array.isArray(campo.source) && this.DropdownSources ? this.DropdownSources[campo.source] : campo.source
    if (campo.inputType == 'singleDropdown' && typeof campo.internalOutput == 'string' && !this.Oggetto[campo.internalOutput] && this.Oggetto[campo.OutputField] && elements)
      this.Oggetto[campo.internalOutput] = elements?.find(x => x[campo.settings?.idField ?? 'id'] == this.Oggetto[campo.OutputField])
    if (campo.inputType == 'singleDropdown' && elements?.length == 1 && typeof campo.internalOutput == 'string' && campo.settings?.selectIfSingle)
      this.Oggetto[campo.internalOutput] = elements[0]
    if (campo.inputType == 'singleDropdown' && typeof campo.internalOutput == 'string' && campo.settings?.selectFirst && campo.source && !this.Oggetto[campo.internalOutput])
      this.Oggetto[campo.internalOutput] = elements[0]
    return elements;
  }

  getparam(oggetto, param) {
    return oggetto ? oggetto[param] : null;
  }

  mapCustomField(check: { check: string, id: number, fields: { label: string, name: string, type: 'number' | 'dropdown' | 'string', values: any[], tariffe?: { max?: number, min: number, prezzo: number }[] }[] }) {
    return check?.fields?.map(field => {
      const campo: CampoAggiuntivo = {
        nome: field.label,
        oggetti: [field.name],
        modificabile: true,
        OnChange: "getPrice",
        class: check.fields.length > 1 ? 'col-lg-6' : 'col-12',
        tariffe: field.tariffe
      }
      switch (field.type) {
        case 'dropdown':
          campo.inputType = 'singleDropdown';
          campo.source = field.values;
          campo.internalOutput = field.name;
          campo.OutputObject = field.name + "Object";
          campo.settings = {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'label',
          }

          break;
        case "number":
          campo.inputType = 'number';
          break;
        case "string":
          campo.inputType = 'text';
          break;
      }
      return campo
    })
  }

  IsArray(val) {
    return val && Array.isArray(val);
  }

  getObject(campo: CampoAggiuntivo, i: number) {
    return this.getValue(this.Oggetto, campo, i)
  }
  public getValue(Oggetto, campo: CampoAggiuntivo, i: number) {
    if (campo.type == 'object' && Oggetto[campo.oggetti[i]] && Oggetto[campo.oggetti[i]][campo.field[i]] && typeof Oggetto[campo.oggetti[i]][campo.field[i]] !== 'string')
      return campo.field[i + 1] ? Oggetto[campo.oggetti[i]][campo.field[i]][campo.field[i + 1]] : {}
    else if (Oggetto[campo.oggetti[i]] && (campo.type == 'object' || (typeof Oggetto[campo.oggetti[i]] == 'object')))
      return Oggetto[campo.oggetti[i]][campo.field[i]]
    else
      return Oggetto[campo.oggetti[i]]
  }

  FilterHandler(campoAggiuntivo: CampoAggiuntivo, $event: any) {
    this.OnFilter.emit({ OnFilter: campoAggiuntivo.OnFilter, Event: $event })
    if (campoAggiuntivo?.parameter?.some(x => x.search)) {
      this.getSource(campoAggiuntivo, $event)
    }
  }
  sendMail(
    to?: string[],
    subject?: string,
    body?: string,
    cc?: string[],
    bcc?: string[]
  ): void {
  // Funzione per creare una stringa con i parametri del mailto
  const encodeParam = (param: string): string => encodeURIComponent(param);

  // Base del link mailto
  let mailto = "mailto:";

  // Aggiungere destinatari principali (to)
  if (to && to.length > 0) {
    mailto += to.join(",");
  }

  // Costruire i parametri query
  const params = new URLSearchParams();

  if (subject) params.append("subject", subject);
  if (body) params.append("body", body);
  if (cc && cc.length > 0) params.append("cc", cc.join(","));
  if (bcc && bcc.length > 0) params.append("bcc", bcc.join(","));

  // Aggiungere i parametri al link mailto
  const queryString = params.toString();
  if (queryString) {
    mailto += `?${queryString}`;
  }

  // Eseguire l'azione
  window.location.href = mailto;
}



  ChangeHandler(OnChange: string, $event: any, CampoAggiuntivo: CampoAggiuntivo, CampoPadre?: CampoAggiuntivo) {
    if(OnChange == 'mailTo'){
      var email = $event;
      this.sendMail(
        [email],
        "Ticket:" + this.Oggetto.id,
      );
    }
    //console.log('event',$event );
    if (CampoAggiuntivo.OutputField && this.Oggetto && !$event) {
      this.Oggetto[CampoAggiuntivo.OutputField] = null;
    }
    else if (CampoAggiuntivo.OutputField && this.Oggetto && $event) {
      this.Oggetto[CampoAggiuntivo.OutputField] = $event[CampoAggiuntivo?.settings?.idField ?? 'id'] ?? null;
      //console.log(`selected id: ${$event?.id} copied to ${CampoAggiuntivo.OutputField}: ${this.Oggetto[CampoAggiuntivo.OutputField]}`);
    }
    if (CampoAggiuntivo.OutputField && !CampoAggiuntivo?.settings?.singleOutput && !$event) {
      this.Oggetto[CampoAggiuntivo.OutputField] = null;
    }
    if (CampoAggiuntivo.OutputField && !CampoAggiuntivo?.settings?.singleOutput) {
      this.Oggetto[CampoAggiuntivo.OutputField] = $event?.map(x => x.id) ?? null;
      //console.log(`selected ids: ${$event?.map(x=> x.id)} copied to ${CampoAggiuntivo.OutputField}: ${this.Oggetto[CampoAggiuntivo.OutputField]}`);
    }
    if (CampoAggiuntivo.OutputObject) {
      this.Oggetto[CampoAggiuntivo.OutputObject] = $event ?? null;
      //console.log(`selected value: ${$event} copied to ${CampoAggiuntivo.OutputObject}: ${this.Oggetto[CampoAggiuntivo.OutputObject]}`);
    }
    this.OnChange.emit({ OnChange: OnChange, Event: $event, CampoAggiuntivo: CampoPadre ?? CampoAggiuntivo })
    if (CampoAggiuntivo?.OnChangeFunc)
      CampoAggiuntivo.OnChangeFunc(this.Oggetto, $event, this.apiPaths);

  }

  async filechange(OnChange, event, campoAggiuntivo: CampoAggiuntivo) {
    const file: Blob = event.target.files[0];
    this.SetFile(file, event.target.files[0].name, campoAggiuntivo)
  }

  SetFile(file, filename, campoAggiuntivo: CampoAggiuntivo) {
    if (file.size > 78643200) {
      this.toastService.warning("Questo file è troppo grande per essere caricato.", "Attenzione!")
      return;
    } else {
      console.log("FileSize: ", file.size);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result.toString().length > 100) {
          if (campoAggiuntivo?.multipart) {
            this.Oggetto[campoAggiuntivo?.oggetti[0] ?? 'allegato'] = file;
          } else
            this.SetBase64File(reader.result.toString(), filename, campoAggiuntivo)
        } else
          this.Oggetto.allegato = undefined;
      };
    }
    this.OnChange.emit({ OnChange: campoAggiuntivo.OnChange, Event: file })
  }

  SetBase64File(file, filename, campoAggiuntivo: CampoAggiuntivo) {
    if ((file ?? '').length <= 100) return;
    this.Oggetto[campoAggiuntivo?.oggetti[0] ?? 'allegato'] = file;
    this.Oggetto[campoAggiuntivo.nameField ?? ((campoAggiuntivo?.oggetti[0] ?? '') + 'file')] = filename;
  }

  hasAzioni(azioni, fields) {
    return fields?.some(field => azioni?.some(azione => this.campoUtility.ButtonShow(azione, field)));
  }

  allSelected(headerField: any, data: any[]) {
    return data.every(x => this.campoUtility.getObject(headerField, x, 0));
  }

  toggleAll(headerField: any, data: any[]) {
    var oldValue = Object.assign({}, { val: this.allSelected(headerField, data) })
    data.forEach((x) => x[headerField.oggetti[0]] = !oldValue.val)
  }

  AddClick(campo: CampoAggiuntivo) {
    if (campo.AddClick) {
      campo.AddClick.emit()
    } else {
      this.OnChange.emit({ OnChange: campo.ExternalAddClick, Event: {} })
    }
  }

  GetImageID(id_immagine: any) {
    return id_immagine > 0 ? `${this.apiPaths.getBaseAPI()}/file/image/${id_immagine?.toString()}` : undefined;
  }
  async ImageChange(event, target, type: 'image' | 'pdf', filename?) {
    const blob: Blob = event.target.files[0];
    if (!blob.type.includes(type)) {
      this.toastService.warning("Questo formato non è supportato. Inserire solo " + type + ".", "Attenzione!")
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      this.Oggetto[target] = reader.result.toString().length > 1 ? reader.result.toString() : undefined;
      if (!filename) return;
      this.Oggetto[filename] = reader.result.toString().length > 1 ? event.target.files[0].name : this.Oggetto[filename];
    };
  }
  onImageError(prodotto: Prodotto) {
    // prodotto.UrlImmagine = 'assets/images/gallery/NoImageAvailable.png';
  }

  drawComplete(campo: any, pad: SignaturePad) {
    var value = pad.toDataURL("image/png");
    this.SetBase64File(value, `Firma-${this.Oggetto?.id?.toString()}`, campo)
  }
  cancellaFirma(pad: SignaturePad) {
    pad.clear();
  }
  CreateParams(param: any, Oggetto: any) {
    var newTask = {};
    param?.concat?.forEach(concatObj => {
      var p = concatObj.from?.split('.');
      var parameter = Object.assign({}, Oggetto)
      p?.forEach(el => {
        parameter = this.getparam(parameter, el);
      })// TODO: gestire array meglio!!

      newTask[concatObj.to] = newTask[concatObj.to] ? newTask[concatObj.to] + parameter : parameter;
    });
    param?.copy?.forEach(concatObj => {
      if (concatObj.from)
        newTask[concatObj.to] = Oggetto[concatObj.from];
      else if (concatObj.value)
        newTask[concatObj.to] = concatObj.value;
    });
    return newTask;
  }

  toggleDay(oggetto, param) {
    this.campoUtility.getObj(this.Oggetto, oggetto)[param] = !this.campoUtility.getObj(this.Oggetto, oggetto)[param];
  }


  getHeadField(headField, campo: CampoAggiuntivo) {
    var _headField = Object.assign({}, headField);
    _headField.modificabile = headField.modificabile && !this.campoUtility.Disable(campo, this.Oggetto)
    return _headField
  }

  Disable(campo, Oggetto) {
    return this.isModifyDisabled || this.campoUtility.Disable(campo, Oggetto);
  }
  customButton(oggetti, button){
    this.CustomButton.emit(button);
  }

}
