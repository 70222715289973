import { Component, Inject, Input } from "@angular/core";

@Component({
    selector: 'util-alert',
    template: `
        <a href="javascript:void(0)"
               [ngbTooltip]="tooltip"
               [ngClass]="aclass"
               >
              <i [ngClass]="iclass" [ngStyle]="{ 'color': color }"></i>
            </a>
    `,
})
export class Alert {
    tooltip: string = '';
    aclass: string = 'link font-16 text-danger font-weight-bold';
    iclass: string = 'fas fa-exclamation-triangle text-warning';
    color: string;
    
}

