import {Task} from "../../task/models/Task";
import {ClassicType} from "../../shared/models/ClassicType";
import {Azienda} from "../../Admin/models/Azienda";
import {Scadenza} from "../../Scadenzario/Models/Scadenza";
import {SerialKey} from "./serial-key";
import {Persona} from "../../Admin/models/persona";
import {VoceProgetto} from "../../Progetti/Models/VoceProgetto";
import {CampoAggiuntivo} from "../../shared/models/campo-aggiuntivo";
import {ProvaAcquisto} from "./prova-acquisto";

export class Rma extends Task {
  prodottoId?: number;
  garanzia?: boolean;
  note?: string;
  difetto?: number;
  difettoDesc?: string;
  difettoObject?: ClassicType;
  allegato?: string;
  file?: string;
  prodotto?: SerialKey;
  estensione?:any;
  ritiro?: Azienda;
  manager?: any;
  cliente?: Azienda;
  noteTecnico?: string;
  telefono?: string;
  tipoObject?: ClassicType;
  chiuso?: boolean;
  priorita?: number;
  prioritaObject?: any;
  figli?: Scadenza[];
  photo?: any[];
  padreObject?: any;
  acquirente?: Azienda;
  seriale?: string;
  rivenditore?: string
  stato?: string
  statoObject?: ClassicType;
  acquisto?: string | number;
  acquistoObject?:ClassicType
  brand?: string
  brandObject?: ClassicType;
  clienteId?: number;
  type?: string;
  assistenza?: Azienda;
  assistenzaObject?: Azienda;
  assistenzaId?: number;
  referenti?: Persona[] ;
  voci?: VoceProgetto[];
  totale?: number;
  data_apertura_centro?: any;
  data_assegnazione_tec?: any;
  brandDesc?: string;
  provaId?: number;
  ricevuta?: number;
  ricevutaId?: number;
  targhettaId?: number;
  campiAggiuntivi?: {check:string, id: number, risposta: boolean, fields: CampoAggiuntivo[]}[];
  attivita?: { check?: string; id?: number; risposta?: boolean; fields?: CampoAggiuntivo[] }[];
  managerObject?: Persona;
  sedeId?: any;
  proveObject?: ProvaAcquisto[];
  prove?: number[];
  sedeObject?: any;
  padre?: number;
  progressivo?: any;

  constructor(provaId?: number) {
    super();
    this.garanzia = false;
    this.provaId = provaId;
    this.ritiro = new Azienda();
    this.acquirente = new Azienda();
  }
}

