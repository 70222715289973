<div class="modal-header">
  <h5 class="modal-title" id="editUserLabel">{{Rma.padre ? 'Intervento' : 'RMA.title' | translate }} </h5>
  <button type="button" class="close" (click)="cancelClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="environment.RmaAddOn['CampiRmaAdd']">
  <app-sector-viewer [(DropdownSources)]="DropdownSources" (OnFilter)="FilterHandler($event)" (OnChange)="OnChengeHandler($event)" [(Campi)]="environment.RmaAddOn['CampiRmaAdd']" [(Oggetto)]="Rma"></app-sector-viewer>
  <div *ngIf="environment.RmaAddOn['ProveAcquistoMultipleRma'] && Rma?.acquirente" >
    <div class="row">
      <div class="col-lg-9" *ngIf="Rma?.sedeId">
        <label for="categoria" class="control-label col-form-label">{{'provaAcquisto.title'|translate}}:</label>
        <ng-multiselect-dropdown [settings]="PAdropdownSettings" [placeholder]="'Seleziona'"
                                 [data]="ApprestamentiSelezionabili()" [(ngModel)]="selectedPA" >
        </ng-multiselect-dropdown>
      </div>
      <div class="col-lg-3 d-flex align-items-end" *ngIf="Rma?.sedeId">
        <div class="d-flex align-items-end">
          <button type="submit" (click)="addPA(selectedPA)" class="ml-auto btn btn-outline-dark p-2 waves-effect waves-light">
            <span class="ml-1"> Aggiungi </span> &nbsp;
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <br>
    <app-lista-prova-acquisto  [Headers]="environment.RmaAddOn['ListProvaAcquistoRmaField']"  [(List)]="Rma.proveObject" [Cliente]="Rma.acquirente" [isModifyDisabled]="true"  [Local]="true" [HasFilter]="false" [HasHeader]="false" [(Sede)]="Rma.sedeId" ></app-lista-prova-acquisto>
    <br>
    <div class="card" *ngIf="!Rma.id && environment.RmaAddOn.Allegati">
      <div class="d-flex align-items-center bg-white">
        <h4 class="card-title">{{'RMA.allegati' | translate}}</h4>
        <div class="ml-auto  mt-md-0">
          <button type="button" (click)="addAllegato()" class="btn btn-cyan "><i
              class="fas fa-plus  font-14 mr-1 text-white">&nbsp;{{'RMA.allegati' | translate}}</i>
          </button>
        </div>
      </div>
    
      <div class="card-body">
    
        <div class="d-md-flex align-items-center bg-white p-2">
          <h7 class="d-flex align-items-center p-2 ">{{'RMA.infoAllegati' | translate}}</h7>
        </div>
        <div class="table-responsive" *ngIf="Rma.photo">
          <table class="table bg-white table-hover align-middle mb-0 no-wrap">
            <thead>
              <tr>
                <th>File</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let photo of Rma.photo; let i = index">
                <td>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" [id]="'allegato' + i"
                      (change)="OnAllegatoFileChange($event,i)">
                    <label class="custom-file-label" for="ricevuta">{{ Rma?.photo && Rma?.photo[i]?Rma?.photo[i].name :
                      "Nessun File Allegato"}}</label>
                  </div>
                </td>
                <td class="align-middle">
                  <a href="javascript:void(0)" class="link font-16 text-danger font-medium mr-2"
                    (click)="removeAllegato(i)">
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    
        <br>
        <br>
      </div>
    </div>
    <br><br><br><br><br><br><br><br><br><br>
  </div>

</div>

<div class="d-flex align-items-center justify-content-end m-t-10 m-b-10 m-r-10">
  <button type="submit"  [disabled]="!enableUploadBtn && activeId == 'ritiro'" (click)="save()" class=" btn  btn-success  waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.save' | translate}} </span>
    <span class="ml-1 text-white font-weight-bold" *ngIf="!enableUploadBtn">{{uploadPercent}}%</span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>


<input type="file" #fileInputTarghetta (change)="OnTarghettaFileChange($event)" style="display: none" />
<input type="file" #fileInputRicevuta (change)="OnRicevutaFileChange($event)" style="display: none" />