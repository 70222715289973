import { Paginazione } from './../../shared/models/paginazione';
import { RitornoPaginazione } from './../../shared/models/RitornoPaginazione';
import { Ruolo } from './../models/Ruolo';
import { ApiRestService } from './api-rest.service';
import { Persona } from '../models/persona';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { error } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class PersonaleStoreService {
  private LISTA_PERSONALE = new BehaviorSubject<RitornoPaginazione<Persona[]>>(null);
  private TUTTI_RUOLI = new BehaviorSubject<string[]>([]);
  private TUTTI_RUOLI_ASOBJ = new BehaviorSubject<Ruolo[]>([]);
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private SHOW_ADD_FORM = new BehaviorSubject<boolean>(false);
  private SELECTED_USER = new BehaviorSubject<Persona>(null);
  private SHOWALLUSER = new BehaviorSubject<boolean>(false);
  constructor(private apiRestService: ApiRestService) {
    this.getListaRuoliAsObject();
  }
  private dataStore: {
    ListaPersonale: RitornoPaginazione<Persona[]>,
    TuttiRuoli: string[],
    TuttiRuoliAsObject: Ruolo[],
    Editable: boolean,
    ShowAddForm: boolean,
    SelectedUser: Persona,
    ShowAllUser: boolean,
  } = {
      ListaPersonale: null,
      TuttiRuoli: [],
      TuttiRuoliAsObject: [],
      Editable: false,
      ShowAddForm: false,
      SelectedUser: null,
      ShowAllUser: false,
  }
  readonly $listaPersonale = this.LISTA_PERSONALE.asObservable();
  readonly $listaTuttiRuoli = this.TUTTI_RUOLI.asObservable();
  readonly $listaTuttiRuoliAsObject = this.TUTTI_RUOLI_ASOBJ.asObservable();
  readonly $editable = this.EDITABLE.asObservable();
  readonly $showAddForm = this.SHOW_ADD_FORM.asObservable();
  readonly $selectedUser = this.SELECTED_USER.asObservable();
  readonly $showAllUser = this.SHOWALLUSER.asObservable();
  refreshPersonale = () => this.LISTA_PERSONALE.next(Object.assign({}, this.dataStore).ListaPersonale);
  refreshTuttiRuoli = () => this.TUTTI_RUOLI.next(Object.assign({}, this.dataStore).TuttiRuoli);
  refreshTuttiRuoliAsObject = () => this.TUTTI_RUOLI_ASOBJ.next(Object.assign({}, this.dataStore).TuttiRuoliAsObject);
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshShowAddForm = () => this.SHOW_ADD_FORM.next(Object.assign({}, this.dataStore).ShowAddForm);
  refreshSelectedUser = () => this.SELECTED_USER.next(Object.assign({}, this.dataStore).SelectedUser);
  refreshShowAllUser = () => this.SHOWALLUSER.next(Object.assign({}, this.dataStore).ShowAllUser);
  //#region gets

  getListaStudenti(value) {
    this.apiRestService.getListaPersonale(value).subscribe(data => {
      const studenti = data.data.filter((user: Persona) => 
        user.ruoliObject.some(ruolo => ruolo.label === 'Studente')
      );
      console.log('Studenti:', studenti); // Debug log
      this.dataStore.ListaPersonale = { ...data, data: studenti };
      this.refreshPersonale();
      this.LISTA_PERSONALE.next({ data: studenti, total: studenti.length });
    });
    
    console.log('Restituzione Observable:', this.$listaPersonale);
    return this.$listaPersonale;
  }

  getListaPersonale(value) {
    if (!this.dataStore.ShowAllUser) {
      this.apiRestService.getListaPersonale(value)
        .subscribe(data => {
          this.dataStore.ListaPersonale = data;
          this.refreshPersonale();
        });
      return this.$listaPersonale;
    } else {
      return this.getListaAllPersonale(value);
    }
  }
  getListaTecnici(value) {
    this.apiRestService.getListaTecnici(value)
      .subscribe(data => {
        this.dataStore.ListaPersonale = data;
        this.refreshPersonale();
      });
    return this.$listaPersonale;
  }
  getListaAllPersonale(value) {
    this.apiRestService.getListaTuttoPersonale(value)
        .subscribe(data => {
          this.dataStore.ListaPersonale = data;
          this.refreshPersonale();
        });
      return this.$listaPersonale;
  }
  searchPersonale(value) {
    this.apiRestService.searchPersonale(value)
        .subscribe(data => {
          this.dataStore.ListaPersonale = data;
          this.refreshPersonale();
        });
      return this.$listaPersonale;
  }
  getListaRuoli() {
    this.apiRestService.getTuttiRuoliAsString()
      .subscribe(data => {
        this.dataStore.TuttiRuoli = data;
        this.refreshTuttiRuoli();
      });
  }
  getListaRuoliAsObject() {
    this.apiRestService.getTuttiRuoliAsObject()
      .subscribe(data => {
        this.dataStore.TuttiRuoliAsObject = data;
        this.refreshTuttiRuoliAsObject();
      });
    return this.$listaTuttiRuoliAsObject;
  }
  //#endregion
  //#region updates
  updateSelectedUser(value: Persona) {
    if(value.id)
      this.apiRestService.getInfoPersonale(value).subscribe(data => {
        this.dataStore.SelectedUser = data;
        this.refreshSelectedUser();
      });
    else {
      this.dataStore.SelectedUser = value;
      this.refreshSelectedUser();
    }
  }
  updateSelectedUserWhenProfile(value: Persona) {
    this.apiRestService.getInfoProfilo(value).subscribe(data => {
      this.dataStore.SelectedUser = data;
      this.refreshSelectedUser();
    });
  }
  updateProfilo(value: Persona) {
    this.apiRestService.updateProfilo(value).subscribe((data) => {
      this.updateSelectedUserWhenProfile(null);
    });
  }
  updateShowAllUser(value: boolean) {
    this.dataStore.ShowAllUser = value;
    this.refreshShowAllUser();
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
  updateShowAddForm(value: boolean) {
  this.dataStore.ShowAddForm = value;
  this.refreshShowAddForm();
  }
  updateuser(value: Persona) {
    this.apiRestService.updateUser(value).subscribe((data) => {
      this.getListaPersonale(new Paginazione(1,1000));
    });
  }
  renewUser(value: Persona) {
    this.apiRestService.restoreUser(value).subscribe((data) => {
      this.getListaPersonale(new Paginazione(1,1000));
    });
  }
  //#endregion
  //#region add
  addNewUser(value: Persona) {
    this.apiRestService.addNewUser(value).subscribe((data) => {
      this.getListaPersonale(new Paginazione(1,1000));
    });
  }
  //#endregion
  //#region delete
  deleteUser(value: Persona) {
    this.apiRestService.deleteUser(value).subscribe((data) => {
      this.getListaPersonale(new Paginazione(1,1000));
    });
  }
  //#endregion
  //#region utility
  nominativo(utente: Persona): string {
    return utente.cognome + " " + utente.nome;
  }
  clienteByNominativo(nominativo: string): Persona {
    return this.dataStore.ListaPersonale.data.find(x => this.nominativo(x) === nominativo);
  }
  listaNominativi(): string[] {
    const result: string[] = [];
    if(this.dataStore.ListaPersonale && this.dataStore.ListaPersonale.data)
      this.dataStore.ListaPersonale.data.forEach(cliente => result.push(this.nominativo(cliente)));
    return result;
  }
  //#endregion

}
