import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from '../../Login/_services/authentication.service';
import {environment} from "../../../environments/environment";
import { ApiPathsService } from 'src/app/api-paths.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
    private toastService: ToastrService,
              private Api: ApiPathsService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let errorMessage = err.error.message || err.statusText;
      if (err.status === 401 && !environment.Wp && request?.url?.toLowerCase()?.includes('login')) {
        this.toastService.error("Autenticazione Fallita, Credenziali non valide", 'Oops!');
        return throwError(errorMessage);
        //location.reload();
      }
      if (err.status === 401 && !environment.Wp && !request?.url?.toLowerCase()?.includes('login')) {
        this.toastService.error("Accesso negato.\n" + request?.url, 'Oops!');
        this.authenticationService.logout();
        return throwError(errorMessage);
        //location.reload();
      }
      if (err.status === 422) {
        // errore di validazione
        errorMessage = "";
        for (const field in err.error) {
          errorMessage += field + " : " + err.error[field] + '\n';
        }
      }
      if (err.status === 500) {
        errorMessage= `Errore non gestito del server:\n${errorMessage}`
        if(environment['TelegramBotToken'] && environment['TelegramChatId'])
        this.Api.ClassicPost(
          `https://api.telegram.org/bot${environment['TelegramBotToken']}/sendMessage`,
          {
            chat_id: environment['TelegramChatId'],
            text: `${environment['TelegramBotPlatformName'] ?? ""}:\n    Link: ${request.url}\n    Errore :\n      ${err.error.message ?? errorMessage}\n ${err.error.file}:${err.error.line} \n    Utente: ${this.authenticationService?.currentUserValue?.username} \n    Token: ${this.authenticationService?.currentUserValue?.access_token}`
          }).subscribe()
      }
      if (err.status === 504) {
        if(environment['TelegramBotToken'] && environment['TelegramChatId'])
          this.Api.ClassicPost(
            `https://api.telegram.org/bot${environment['TelegramBotToken']}/sendMessage`,
            {
              chat_id: environment['TelegramChatId'],
              text: `${environment['TelegramBotPlatformName'] ?? ""}:\n    Link: ${request.url}\n    Errore Timeout:\n      ${errorMessage}\n    Utente:\n      ${JSON.stringify(this.authenticationService?.currentUserValue ?? {})}`
            }
          ).subscribe()
      }
      if (environment.Wp) {
        // errore di validazione
        errorMessage = "";
        for (const field in err.error) {
          errorMessage += field + " : " + err.error[field] + '\n';
        }
      }
      if(request.url.includes(environment.apiUrl))
        this.toastService.error(errorMessage, 'Oops!');
      return throwError(errorMessage);
    }));
  }
}
