import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Rma} from "../_Models/rma";
import {Subscription} from "rxjs";
import {ClassicType} from "../../shared/models/ClassicType";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DownloadService} from "../../shared/_services/download.service";
import {Location} from "@angular/common";
import {ApiRestService} from "../_services/api-rest.service";
import {RmaStoreService} from "../_services/rma-store.service";
import {ToastrService} from "ngx-toastr";
import {ApiPathsService} from "../../api-paths.service";
import {Azienda} from "../../Admin/models/Azienda";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpEventType} from "@angular/common/http";
import { ApiRestService as  AdminApiRestService } from '../../Admin/_service/api-rest.service';
import {Permission} from "../../Login/_guards/Permission";
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";
import {ProvaAcquisto} from "../_Models/prova-acquisto";
import {RicercaPaginazione} from "../../Admin/models/sendpersonale";
import {RmaData} from "../RmaData";
import {CampoAggiuntivo} from "../../shared/models/campo-aggiuntivo";

@Component({
  selector: 'app-rma-add',
  templateUrl: './rma-prodotto-add.component.html'
})
export class RmaProdottoAddComponent implements OnInit {
  @Output() updated = new EventEmitter<Rma>();
  @Output() added = new EventEmitter<Rma>();
  @Output() onClose = new EventEmitter();
  environment = environment;
  Estensione:  any = undefined;
  Ricevuta: any = undefined;
  Targhetta: any = undefined;
  DropdownSources: any = {
    AziendeSource: [],
    SediSource: [],
    difettilist: [],
    ProveAcquisto: [],
    PersoneSource: [],
    PrioritaSource:[],
    PeriodiSource:[]
  };
  IsModal: boolean = true;
  subscriptions: Subscription = new Subscription();
  Rma: Rma = new Rma();
  types: ClassicType[] = [];
  Difetti: any[] = [];
  dropdownSettings: IDropdownSettings = {singleSelection: true, closeDropDownOnSelection: true, textField: 'label', idField: 'id'};
  PAdropdownSettings: IDropdownSettings = {
    singleSelection: false,
    closeDropDownOnSelection: false,
    singleOutput: false,
    idField: 'id',
    enableCheckAll:true,
    textField: 'nome',
    searchPlaceholderText: "Cerca",
    allowSearchFilter: true,
    allowRemoteDataSearch: false,
  };
  DescRequired: boolean = false;
  saveDisabled: boolean = false;
  enableUploadBtn: boolean = true;
  uploadPercent: number;
  Acquisti: any;
  Brand: ClassicType[] = [];
  Brands: ClassicType[] = [];
  ruolocompleto: string = 'xy';
  activeId: 'prodotto' | 'difetto' | 'allegati' | 'cliente' | 'ritiro' = this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto ? 'prodotto' : 'difetto' ;
  selectedPA: ProvaAcquisto[];
  campiAggiuntiviService: CampoAggiuntivo[] = [
    {
      nome: '',
      source: '/rma/rma/guasti',
      responseHasData: false,
      prevDivClass: "col-md-12",
      oggetti: ['guasto'],
      internalOutput: 'guastoObject',
      OutputField: 'guasto',
      modificabile: true,
      inputType: 'singleDropdown',
      placeholder: 'Guasto',
      settings: {
        singleSelection: true,
        closeDropDownOnSelection: true,
        singleOutput: true,
        idField: 'id',
        textField: 'label',
        searchPlaceholderText: "Cerca",
        allowSearchFilter: true,
        allowRemoteDataSearch: true,
      },
      class: 'col-lg-12',
      visibleifProhibited: true
    },
  ]
  CampiRmaAddData: { sezione: string, campi: CampoAggiuntivo[] }[] = environment.RmaAddOn['CampiRmaAdd'];
  constructor (
    private modalService: NgbModal,
    private downloadService: DownloadService,
    private location: Location,
    private apiRestService: ApiRestService,
    private RmaStore: RmaStoreService,
    private toastService: ToastrService,
    private apiPaths: ApiPathsService,
    private rmaData: RmaData,
    private http: HttpClient,
    private adminApiRestService: AdminApiRestService,
    public permission: Permission,
  ) { }

  @ViewChild('fileInputRicevuta') fileInputRicevuta!: ElementRef<HTMLInputElement>;
  @ViewChild('fileInputTarghetta') fileInputTarghetta!: ElementRef<HTMLInputElement>;

  riempiDropdown(): void {
    this.Rma.provaObject = this.Rma.provaId ? this.Rma.proveObject.find(x => x.id == this.Rma.provaId) : undefined;
    this.Rma = Object.assign(this.Rma, this.Rma.provaObject);
    this.Rma.id = undefined;
    if(environment.RmaAddOn.Brand &&( this.permission.isOperativeRole(this.ruolocompleto)|| !environment.RmaAddOn.provaAcquisto))
      this.apiRestService.getProdottiKeyTypes().subscribe(data => {this.Brands = data;});
    if (environment.RmaAddOn.CircostanzaDifetto && ( this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto)) {
      this.RmaStore.GetTypes();
      this.subscriptions.add(this.RmaStore.$types.subscribe(data => this.DropdownSources.difettilist = data ?? []));
    }
    if (this.CampiRmaAddData?.find((x)=> x.campi?.find((y: CampoAggiuntivo ) => y['type'] == 'checkboxArray')))
      this.apiRestService.GetCampiAggiuntiviRmas(this.Rma?.attivita?.filter(x=>x.risposta)?.map(x=>x.id),"attivita").subscribe(data=> this.Rma.attivita = data )
    if (this.CampiRmaAddData?.find(x => x.campi?.find((y: CampoAggiuntivo) =>y['source'] == 'PeriodiSource'))){
      this.apiPaths.ClassicGet('/rma/rma/periodi').subscribe(data=>{
        this.DropdownSources.PeriodiSource = data;
      })
    }
    if (this.CampiRmaAddData?.find(x => x.campi?.find((y: CampoAggiuntivo) =>y['source'] == 'SediSource')) && (this.Rma?.clienteObject?.id ??this.Rma?.sedeObject?.clienteObject?.id))
      this.adminApiRestService.getSediCliente({id: this.Rma?.clienteObject?.id ?? this.Rma?.sedeObject?.clienteObject?.id}).subscribe(data=> {
        this.DropdownSources.SediSource = data;
        // this.Rma.sedeObject = data.find(x=>x.id == this.Rma.sedeId);
      } )
    if (this.CampiRmaAddData?.find(x => x.campi?.find((y: CampoAggiuntivo) =>y['source'] == 'AziendeSource')))
      this.adminApiRestService.getListaAziende({page:0, size: 10}).subscribe(data=> this.DropdownSources.AziendeSource = data?.data ?? [] );
    if (this.CampiRmaAddData?.find(x => x.campi?.find((y: CampoAggiuntivo) =>y['source'] == 'PrioritaSource')))
      this.apiPaths.ClassicGet('/rma/rma/priorita').subscribe(data=> this.DropdownSources.PrioritaSource = data ?? [] );
    if (this.CampiRmaAddData?.find(x => x.campi?.find((y: CampoAggiuntivo) =>y['source'] == 'difettilist'))){
      this.RmaStore.GetTypes();
      this.subscriptions.add(this.RmaStore.$types.subscribe(data => this.DropdownSources.difettilist = data ?? []))
    }

  }
  async OnRicevutaFileChange(event) {
    if (!event.target.files[0].type.includes("image") && !event.target.files[0].type.includes("pdf")) {
      this.toastService.warning("Questo formato non è supportato. Inserire solo immagini o PDF.", "Attenzione!")
      return;
    }
    this.Ricevuta = event.target.files[0];
  }
  async OnTarghettaFileChange(event) {
    if (!event.target.files[0].type.includes("image") && !event.target.files[0].type.includes("pdf")) {
      this.toastService.warning("Questo formato non è supportato. Inserire solo immagini o PDF.", "Attenzione!")
      return;
    }
    this.Targhetta = event.target.files[0];
  }
  OnChengeHandler(event: {OnChange: string, Event: any}){
    if(event)
      switch (event?.OnChange) {
        case "uploadTarghetta":
          this.fileInputTarghetta.nativeElement.click();
          break;
        case "uploadRicevuta":
          this.fileInputRicevuta.nativeElement.click();
          break;
        case "OnClienteChange":
          if(event?.Event?.id)
            this.adminApiRestService.getInfoAziende(event.Event).subscribe(data=>{
              if (this.CampiRmaAddData?.find(x => x.campi?.find((y: CampoAggiuntivo) =>y['source'] == 'SediSource')) && event?.Event?.id)
                this.adminApiRestService.getSediCliente({id: event?.Event?.id }).subscribe(data=> {
                  this.DropdownSources.SediSource = data;
                } )
              // this.DropdownSources.SediSource = data?.sedi ?? [];
              this.Rma.acquirente = data;
            })
          else
            this.Rma.acquirente = undefined;
          break;
        case "OnSedeChange":
          if(event?.Event?.id)
            this.apiRestService.getProveAcquistoSede({id: event?.Event?.id}).subscribe(data =>{
              this.DropdownSources.ProveAcquisto = data?.data  ?? [];
            })
          break;
      }

  }

  ApprestamentiSelezionabili(){
    return this.DropdownSources.ProveAcquisto.filter( x=> this.Rma?.proveObject?.find(y=> y.id == x.id) == undefined )
  }

  ngOnInit(): void {
    this.subscriptions.add(this.RmaStore.$selectedProdotto.subscribe(data=> {
      data.data['persona'] = data.data['persona'] ?? {};
      this.Rma = data?.data;
      this.riempiDropdown();
      console.log("Selected RMA: ", this.Rma);
      this.Rma['partenza'] =  "Martina Franca";
    }));
  }

  filtroManager(item: string) {
    if (item.length >= 3) {
      this.adminApiRestService.getListaPersonale(new RicercaPaginazione(item, 0, 1)).subscribe(data=> this.DropdownSources.PersoneSource = data?.data ?? []);
    }
  }

  FilterHandler(event: {OnFilter: string, Event: string}){
    switch (event.OnFilter){
      case "filtroManager":
        this.filtroManager(event.Event);
        break;
    }
  }

  addPA(selectedPA: ProvaAcquisto[]){
    if (this.Rma?.proveObject == undefined)
      this.Rma.proveObject = [];
    if (this.Rma?.prove == undefined)
      this.Rma.prove = [];
    if(selectedPA?.length>0) {
      selectedPA.forEach(provaSelezionata => {
        if (provaSelezionata?.id) {
          this.Rma?.proveObject?.push(provaSelezionata);
          this.Rma?.prove?.push(provaSelezionata?.id);
        }
      })
      this.selectedPA = [];
    }
    else
      this.toastService.warning("Attenzione selezionare un apprestamento");
  }

  public static GetClassicTypeIntFirstID(value: ClassicType[]): number{
    const ID: string = this.GetClassicTypeFirstID(value);
    if(ID)
      return parseInt(ID);
    return null;
  }
  public static GetClassicTypeFirstID(value: ClassicType[]): string{
    if(value && value.length >0 && value[0] && value[0].id)
      return value[0].id;
    return null;
  }

  DynamicFormGetter(sezioni:any[], source: any){
    let _form: FormData = new FormData();
    sezioni.forEach(sezione => {
      _form = ApiPathsService.DynamicFormGetter(sezione.campi, source, _form);
    })
    _form.append("includereKm", (this.Rma['km'] != null && this.Rma['km'] != undefined)==true ? '1' : '0');
    if(environment.RmaAddOn.provaAcquisto && this.Rma?.provaId )
      _form.append("provaId", this.Rma?.provaId?.toString());
    if(this.Rma?.padre)
      _form.append("padre", this.Rma?.padre?.toString());
    if(environment.RmaAddOn.provaAcquisto && this.Rma?.proveObject?.length > 0 )
      this.Rma.proveObject.forEach((prova, id)=>{
        Object.getOwnPropertyNames(prova).forEach(property=>{
          if(prova[property])
            _form.append(`proveObject[${id}][${property}]`, prova[property]?.toString() ?? '');
        })
      })
    if(this.Rma?.acquirente && Object.getOwnPropertyNames(this.Rma?.acquirente)?.length > 0)
      Object.getOwnPropertyNames(this.Rma?.acquirente).forEach(property=>{
        if(this.Rma?.acquirente[property] && typeof this.Rma?.acquirente[property] == 'string')
        _form.append(`acquirente[${property}]`, this.Rma?.acquirente[property]?.toString() ?? '');
      })
    if(this.Rma?.ritiro && Object.getOwnPropertyNames(this.Rma?.ritiro)?.length > 0)
      Object.getOwnPropertyNames(this.Rma?.ritiro).forEach(property=>{
        if(this.Rma?.ritiro[property] && typeof this.Rma?.ritiro[property] == 'string')
          _form.append(`ritiro[${property}]`, this.Rma?.ritiro[property]?.toString() ?? '');
      })
      if(this.Rma.photo )
      this.Rma.photo.forEach(data => {
        _form.append("photo[]", data)
      })
      if(this.Targhetta)
        _form.append("targhetta", this.Targhetta)
      if ( this.Ricevuta)
        _form.append("ricevuta", this.Ricevuta)
    return _form;
  }
  // FormGetter(){
  //   let formData: FormData = new FormData();
  //   if(environment.RmaAddOn.provaAcquisto && this.Rma?.provaId )
  //     formData.append("provaId", this.Rma?.provaId?.toString());
  //   if(environment.RmaAddOn.Matricola && this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto)
  //     formData.append("seriale", this.Rma.seriale??'')
  //   if(environment.RmaAddOn.Garanzia)
  //   formData.append("garanzia", this.Rma.garanzia? "1" : "0" )
  //   if(environment.RmaAddOn.Difetto) {
  //     formData.append("note", this.Rma.note ?? this.Rma['guastoObject']?.label ?? '')
  //     formData.append("guasto", this.Rma['guasto'] ?? '')
  //   }
  //   if(environment.RmaAddOn.CircostanzaDifetto && this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto)
  //   formData.append("difetto", RmaProdottoAddComponent.GetClassicTypeIntFirstID(this.Difetti).toString())
  //   if(environment.RmaAddOn.Brand && this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto)
  //     formData.append("brand", RmaProdottoAddComponent.GetClassicTypeIntFirstID(this.Brands).toString())
  //   if(environment.RmaAddOn.Brand  && this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto)
  //     formData.append("brandDesc", this.Rma.brandDesc??'')
  //   if(environment.RmaAddOn.DescrizioneDifetto)
  //   formData.append("difettoDesc", this.Rma.difettoDesc??'')
  //   if(environment.RmaAddOn.Telefono)
  //     formData.append("telefono", this.Rma.telefono)
  //   if(environment.RmaAddOn['Ritiro']?.Enabled) {
  //     if(environment.RmaAddOn['Ritiro']?.Nominativo)
  //       formData.append("ritiro[nominativo]", this.Rma.ritiro.nominativo)
  //     if(environment.RmaAddOn['Ritiro']?.Indirizzo)
  //       formData.append("ritiro[indirizzo]", this.Rma.ritiro.indirizzo)
  //     if(environment.RmaAddOn['Ritiro']?.Cap)
  //       formData.append("ritiro[cap]", this.Rma.ritiro.cap)
  //     if(environment.RmaAddOn['Ritiro']?.Citta)
  //       formData.append("ritiro[citta]", this.Rma.ritiro.citta)
  //     if(environment.RmaAddOn['Ritiro']?.Provincia)
  //       formData.append("ritiro[provincia]", this.Rma.ritiro.provincia)
  //     if(environment.RmaAddOn['Ritiro']?.Referente)
  //       formData.append("ritiro[referente]", this.Rma.ritiro.referente)
  //     if(environment.RmaAddOn['Ritiro']?.Telefono)
  //       formData.append("ritiro[telefono]", this.Rma.ritiro.telefono)
  //     if(environment.RmaAddOn['Ritiro']?.Email)
  //       formData.append("ritiro[email]", this.Rma.ritiro.email)
  //     if(environment.RmaAddOn['Ritiro']?.Chiusura)
  //       formData.append("ritiro[chiusura]", this.Rma.ritiro.chiusura)
  //     if(environment.RmaAddOn['Ritiro']?.Disponibilita)
  //       formData.append("ritiro[disponibilita]", this.Rma.ritiro.disponibilita)
  //     if(environment.ClientiAddOn.geolocalizzazione)
  //       formData.append("ritiro[lat]", this.Rma?.ritiro?.lat?.toString())
  //     if(environment.ClientiAddOn.geolocalizzazione)
  //       formData.append("ritiro[lon]", this.Rma?.ritiro?.lon?.toString())
  //   }
  //   if(environment.RmaAddOn['Cliente']?.Enabled){
  //     if(environment.RmaAddOn['Cliente']?.Nominativo)
  //       formData.append("acquirente[nominativo]", this.Rma.acquirente.nominativo)
  //     if(environment.RmaAddOn['Cliente']?.Indirizzo)
  //       formData.append("acquirente[indirizzo]", this.Rma.acquirente.indirizzo)
  //     if(environment.RmaAddOn['Cliente']?.Cap)
  //       formData.append("acquirente[cap]", this.Rma.acquirente.cap)
  //     if(environment.RmaAddOn['Cliente']?.Citta)
  //       formData.append("acquirente[citta]", this.Rma.acquirente.citta)
  //     if(environment.RmaAddOn['Cliente']?.Provincia)
  //       formData.append("acquirente[provincia]", this.Rma.acquirente.provincia)
  //     if(environment.RmaAddOn['Cliente']?.Referente)
  //       formData.append("acquirente[referente]", this.Rma.acquirente.referente)
  //     if(environment.RmaAddOn['Cliente']?.Telefono)
  //       formData.append("acquirente[telefono]", this.Rma.acquirente.telefono)
  //     if(environment.RmaAddOn['Cliente']?.Email)
  //       formData.append("acquirente[email]", this.Rma.acquirente.email)
  //     if(environment.RmaAddOn['Cliente']?.Chiusura)
  //       formData.append("acquirente[chiusura]", this.Rma.acquirente.chiusura)
  //     if(environment.RmaAddOn['Cliente']?.Disponibilita)
  //       formData.append("acquirente[disponibilita]", this.Rma.acquirente.disponibilita)
  //   }

  //   if(this.Rma.photo && environment.RmaAddOn.Allegati)
  //   this.Rma.photo.forEach(data => {
  //     formData.append("photo[]", data)
  //   })
  //   if(this.Estensione && environment.RmaAddOn.EstensioneGaranzia)
  //     formData.append("estensione", this.Estensione)
  //   if(this.Ricevuta && environment.RmaAddOn.Ricevuta  && this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto)
  //     formData.append("ricevuta", this.Ricevuta)
  //   return formData;

  // }
  cancelClick() {
    this.onClose.emit();
    if(this.IsModal)
      this.modalService.dismissAll();
    else
      this.location.back();
  }
  save(){
    if(this.environment.ClientiAddOn.geolocalizzazione && this.fulladdr(this.Rma?.ritiro) != "")
      this.adminApiRestService.getCoordinate(this.fulladdr(this.Rma?.ritiro)).subscribe(response => {
        if ( response?.results?.length > 0 && response?.results[0]?.geometry?.location) {
          this.Rma.ritiro.lat = response.results[0].geometry.location.lat;
          this.Rma.ritiro.lon = response.results[0].geometry.location.lng;
          this.Send();
        } else
          this.toastService.warning("Le informazioni del luogo di interveno non sono stati trovate")
      },() => this.Send())
    else
      this.Send();
  }
  Send(){
    this.saveDisabled = true;
    if(this.Rma?.ritiro){
      this.Rma.ritiro.indirizzo = this.Rma?.sedeObject?.indirizzo ?? this.Rma.ritiro.indirizzo;
      this.Rma.ritiro.cap = this.Rma?.sedeObject?.cap ?? this.Rma.ritiro.cap;
      this.Rma.ritiro.citta = this.Rma?.sedeObject?.citta ?? this.Rma.ritiro.citta;
      this.Rma.ritiro.provincia = this.Rma?.sedeObject?.provincia ?? this.Rma.ritiro.provincia;
    }if(this.Rma?.acquirente){
      this.Rma.acquirente.indirizzo = this.Rma?.sedeObject?.indirizzo ?? this.Rma.acquirente.indirizzo;
      this.Rma.acquirente.cap = this.Rma?.sedeObject?.cap ?? this.Rma.acquirente.cap;
      this.Rma.acquirente.citta = this.Rma?.sedeObject?.citta ?? this.Rma.acquirente.citta;
      this.Rma.acquirente.provincia = this.Rma?.sedeObject?.provincia ?? this.Rma.acquirente.provincia;
    }

    const _data = this.DynamicFormGetter(this.CampiRmaAddData, this.Rma) ;
    if(this.permission.isOperativeRole(this.ruolocompleto) || !environment.RmaAddOn.provaAcquisto || !this.Rma.provaId){ // Add
      this.MultipartPost(_data,"/rma/rma/new")
    } else if(this.Rma && !this.Rma.id){ // Add
      this.MultipartPost(_data,"/rma/rma/add")
    }
  }

  onDifettoChange() {
    this.DescRequired = this.Difetti && this.Difetti.find(x=>x.id == 70 || x.id == 71) != undefined && this.Difetti.find(x=>x.id == 70 || x.id == 71) != null;
  }
  download(id: number) {
    this.apiPaths.ClassicGet(`/file/download/${id.toString()}`,{ headers: this.rmaData.getRmaHeaders(),observe:'response',responseType:'blob'}, this.rmaData.getRmaLink()).subscribe(data => {
      this.downloadService.downloadFile(data)
    });
  }
  addAllegato() {
    this.Rma.photo = this.Rma.photo ?? [];
    this.Rma.photo.push(null);
  }

  removeAllegato(i) {
    this.Rma.photo.splice(i,1);
  }

  async OnEstensioneFileChange(event) {
    if (!event.target.files[0].type.includes("image") && !event.target.files[0].type.includes("pdf")){
      this.toastService.warning("Questo formato non è supportato. Inserire solo immagini o PDF.", "Attenzione!")
      return;
    }
    this.Estensione = event.target.files[0];
  }

  async OnAllegatoFileChange(event,index) {
    if (!event.target.files[0].type.includes("image") && !event.target.files[0].type.includes("pdf") && !event.target.files[0].type.includes("video")){
      this.toastService.warning("Questo formato non è supportato. Inserire solo immagini, video o PDF.", "Attenzione!")
      return;
    }
    this.Rma.photo[index] = event.target.files[0];
  }

  MultipartPost(formdata: FormData, restroute, headers = this.rmaData.getRmaMultipartHeaders()){
    this.http.request('POST', (this.rmaData.getRmaLink() ?? this.apiPaths.getBaseAPI()) + restroute, {
      body: formdata,
      reportProgress: true,
      observe: 'events',
      headers: headers,
      params: null,
    }).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.enableUploadBtn = false;
          this.uploadPercent = Math.round((event.loaded / event.total) * 100);
        } else if (event.type === HttpEventType.Response) {
          if (event.status === 200 || event.status === 201) this.cancelClick();
          else this.handleErrors(event);
        }
      },
      (error) => {
        this.handleErrors(error);
      }
    );
  }
  handleErrors(err?) {
    this.enableUploadBtn = true;
    console.log(err)
    if(err){
      let errorMessage = err.error.message || err.statusText;
      if (err.status === 422) {
        errorMessage = "";
        for (const field in err.error) {
          errorMessage += field + " : " + err.error[field] + '\n';
        }
      }
      this.toastService.error(errorMessage, "OOPS!!");
    }
  }

  fulladdr(cliente: Azienda) : string {
    return (cliente?.indirizzo? cliente.indirizzo : "") + (cliente?.citta? ("," + cliente.citta) : "") + (cliente?.provincia? ("," + cliente.provincia) : "");
  }
}

