<div class="card" *ngIf="!isModifyDisabled">
  <div class="card-body"  style="background-color: #87858525">
    <h4 class="card-title">Valutazione</h4> <a href="javascript:void(0)" (click)="compatta = !compatta">
     {{compatta ? 'Visualizzazione Estesa': 'Visualizzazione Compatta'}}
    </a>
  </div>
</div>

<div *ngIf="CheckList && CheckList.todo && (!compatta && !isModifyDisabled)">
  <div class="card"  *ngFor="let todo of CheckList.todo">
    <div class="card-body p-0" >
      <h4 class="card-title">{{todo.categoria}}</h4>
      <div class="table-responsive" style=" position: relative; overflow: visible;" *ngIf="todo.foglie?.length>0">
        <table class="table bg-white table-hover align-middle p-0" style="margin-bottom: 70px;">
          <tbody>
          <tr *ngFor="let item of todo.foglie">
            <td >
            <span >
              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                <i-feather name="info" class="feather-sm"></i-feather>
              </span>
              {{ item.check }}
            </span>
            </td>
            <td class="align-middle drop-fill" style="width: 50%" >
                  <ng-multiselect-dropdown *ngIf="item" [settings]="dropdownSettings" [placeholder]="'Seleziona'" name="value"
                                             [data]="valueList" [(ngModel)]="item.valoreObject" (ngModelChange)="value(item)">
                  </ng-multiselect-dropdown>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  
  </div>
</div>
<div *ngIf="CheckList && CheckList.todo && (compatta || isModifyDisabled)">
  <div class="card" *ngFor="let todo of CheckList.todo">
    <div class="card-body p-0">
      <h4 class="card-title">{{todo.categoria}}</h4>
      <div *ngFor="let item of todo.foglie">
        <span> {{ item.check }} - <span style="font-weight: 700;">{{item?.valoreObject?.label}}</span></span>
      </div>
    </div>
  </div>
</div>
