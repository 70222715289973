<ul ngbNav #nav="ngbNav" [activeId]="activeId" class="nav-tabs">
    <li *ngFor="let tab of Tabs | ShowTabPipe:''" [ngbNavItem]="tab.id">
      <a ngbNavLink> {{ tab.titolo }} </a>
      <ng-template ngbNavContent>
        <div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
          <ng-container *ngFor="let button of tab['Azioni']">
            <button type="submit" type="button" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''"
              *ngIf="(button.nome != 'Elimina' || !HideDelete ) && campoUtility.ButtonShow(button,Padre)"
              (click)='customButton(Padre,button)'>
              <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
              <span [ngClass]="button['labelClass']"> {{ button['nome'] | translate }} </span>
            </button>
          </ng-container>
        </div>
        <ng-container *ngIf="tab.tipo == 'component'">
            <ng-container  *ngComponentOutlet="tab.component"></ng-container>
        </ng-container>
        <ng-container *ngIf="tab.tipo == 'template'">
            <ng-container  *ngTemplateOutlet="GetTemplate(tab.component)"></ng-container>
        </ng-container>
        <ng-container *ngIf="tab.tipo == 'task'">
          <app-list  *ngIf="tab.params" [cliente]="TaskSettings.cliente" [clienteObject]="TaskSettings.clienteObject" [persona]="TaskSettings.persona" [type]="tab.params.tipo"  [filter]="tab.params.filter ?? 0"  [filtri]="tab.filtri "></app-list>
        </ng-container>
        <ng-container *ngIf="tab.tipo == 'sezioni' && tab.sezioni">
          <app-sector-viewer [(Oggetto)]="Padre" [Campi]="tab.sezioni" [DropdownSources]="{}" (OnChange)="OnChange.emit($event)"></app-sector-viewer>
        </ng-container>
        <ng-container *ngIf="tab.tipo == 'campi' && tab.campi">
          <app-field-viewer [(Oggetto)]="Padre" [Campi]="tab.campi" [DropdownSources]="{}" (OnChange)="OnChange.emit($event)"></app-field-viewer>
        </ng-container>
          <ng-container *ngIf="tab.tipo == 'tab' && tab.campi">
            <app-dynamic-tabs [Tabs]="tab.campi" [CrudSetting]="CrudSetting" [(Padre)]="Padre" [AvailableTemplates]="AvailableTemplates" (OnChange)="OnChange.emit($event)" (CustomButtonClick)="CustomButtonClick.emit($event)"></app-dynamic-tabs>
          </ng-container>
        <ng-container *ngIf="tab.tipo == 'crud' && tab.crud ">
          <app-dynamic-list [CrudId]="tab.crud" [filtro]="GetFiltro(tab.filtro)" [ConcatParams]="CrudSetting['ConcatParams'] ?? {}"></app-dynamic-list>
        </ng-container>
        <div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
          <ng-container *ngFor="let button of tab['Azioni']">
            <button type="submit" type="button" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''"
              *ngIf="(button.nome != 'Elimina' || !HideDelete ) && campoUtility.ButtonShow(button,Padre)"
              (click)='customButton(Padre,button)'>
              <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
              <span [ngClass]="button['labelClass']"> {{ button['nome'] | translate }} </span>
            </button>
          </ng-container>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>

<!-- 
  in personale fare 3 tipi tabs : crud(dove richiamare la listaCrud), sezionie e campi. 
  nelle tab sezioni e campi prevedere le azioni tipo quelle del dynamic edit  
-->

  <ng-template #nontrovato >
  </ng-template>